import React from 'react';
import { Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import classnames from 'classnames';
import tinycolor from 'tinycolor2';
import classNames from 'classnames';
// styles
import useStyles from './styles';

// components
import { Typography, Avatar } from '@mui/material';
// consts
import IconByType from './IconByType';
import config from '../../config';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function NotificationWithPhoto({ variant, ...props }) {
  const classes = useStyles();
  const theme = useTheme();

  const icon = IconByType(props.type);

  const iconWithStyles = React.cloneElement(icon, {
    classes: {
      root:
        props.photoUrl != null
          ? classes.notificationIconOnPhoto
          : classes.notificationIconContainer,
    },
    style: {
      color:
        variant !== 'contained' &&
        theme.palette[props.color] &&
        theme.palette[props.color].main,
    },
  });
  //console.log("props.photoUrl", props.photoUrl);
  const image = props.photoUrl
    ? `${config.baseURLApi}${props.photoUrl}&appCode=${config.APP_CODE}`
    : null;

  return (
    <div
      className={classnames(
        classes.notificationContainer,
        props.className,
        {
          [classes.notificationContained]: variant === 'contained',
          [classes.notificationContainedShadowless]: props.shadowless,
        },
      )}
      style={{
        backgroundColor:
          variant === 'contained' &&
          theme.palette[props.color] &&
          theme.palette[props.color].main,
      }}
    >
      <div
        className={classnames(classes.notificationIconContainer, {
          [classes.notificationIconContainerContained]:
            variant === 'contained',
          [classes.notificationIconContainerRounded]:
            variant === 'rounded',
        })}
        style={{
          backgroundColor:
            variant === 'rounded' &&
            theme.palette[props.color] &&
            tinycolor(theme.palette[props.color].main)
              .setAlpha(0.15)
              .toRgbString(),
        }}
      >
        {image != null ? (
          <>
            <Avatar
              alt={'fio'}
              src={image}
              classes={{ root: classes.avatarProfile }}
            />
            {iconWithStyles}
          </>
        ) : (
          iconWithStyles
        )}
      </div>
      <div className={classes.messageContainer}>
        <div
          className={classNames(
            classes.messageNotificationSide,
            classes.messageNotificationBodySide,
          )}
        >
          <Typography variant="h6">{props.subject}</Typography>
          {props.subject !== props.message && (
            <Typography variant="caption" sx={{ width: '100%' }}>
              {props.message}
            </Typography>
          )}
          <Typography
            variant="caption_date"
            align="right"
            sx={{
              width: '100%',
            }}
          >
            {HL7ToDateToSrtDate(props.dateTime, props.lang)}{' '}
            {HL7ToDateToTime(props.dateTime)}
          </Typography>
        </div>

        {props.extraButton && props.extraButtonClick && (
          <Button
            onClick={props.extraButtonClick}
            disableRipple
            className={classes.extraButton}
          >
            {props.extraButton}
          </Button>
        )}
      </div>
    </div>
  );
}
