import axios, {
  AxiosRequestConfig,
  AxiosInstance,
  AxiosError,
} from 'axios';
import config from '../config';
import { getRefreshToken, signOut } from '../actions/user';
import i18next from 'i18next';
import codes from './errorCodes';
import { isPetKey, isString } from '../helpers';

// eslint-disable-next-line
export function getError(err: any, key = 'message') {
  let serverError = 'Error';
  console.log('err', err);
  if (typeof err === 'string') serverError = err;
  else if (
    typeof err?.response?.data === 'object' &&
    key === 'code' &&
    err?.response?.data?.code != null
  ) {
    serverError = err?.response?.data?.code;
  } else if (
    typeof err?.response?.data === 'object' &&
    (err?.response?.data?.message != null ||
      err?.response?.data?.code != null)
  ) {
    const strAppInfo = localStorage.getItem('appInfo');

    if (strAppInfo != null) {
      const appInfo = JSON.parse(strAppInfo);
      const forPet = appInfo?.uiType === 'petClinic';
      let message = err?.response?.data?.message || '';
      message = message.replace(
        'firstName',
        i18next.t(isPetKey('COMPONENT.FORM_NAME', forPet)),
      );
      message = message.replace(
        'lastName',
        i18next.t(isPetKey('COMPONENT.FORM_LAST_NAME', forPet)),
      );
      message = message.replace(
        'middleName',
        i18next.t('COMPONENT.FORM_MIDLE_NAME'),
      );

      serverError = message;
    } else {
      serverError = err?.response?.data?.message;
    }
  } else if (isString(err.code) && codes[err.code] != null) {
    serverError = i18next.t(`ERROR_CODES.${codes[err.code]}`);
  } else if (err?.response?.status === 404) {
    serverError = i18next.t('ERROR_CODES.404');
  } else if (
    typeof err === 'object' &&
    err?.response?.status != null
  ) {
    serverError = i18next.t('ERROR_CODES.5XX');
  } else if (isString(err?.message) && err?.message !== '') {
    serverError = err?.message;
  }

  return serverError;
}

const requestInterceptor = (request: AxiosRequestConfig) => {
  const authToken = localStorage.getItem('authToken');
  const lang = localStorage.getItem('i18nextLng');

  if (request.headers != null) {
    if (authToken != null)
      request.headers.Authorization = `Bearer ${authToken}`;
    request.headers['app-code'] = config.APP_CODE as string;
    request.headers['lang-code'] = config.langCode(lang) as string;
  }

  return request;
};

// const successInterceptor = (response) => {
//   console.log(`request: `, request);
// };

const errorInterceptor = (error: AxiosError) => {
  if (error.response != null && error.response.status === 401) {
    const refreshToken = localStorage.getItem('refreshToken');
    const authToken = localStorage.getItem('authToken');
    // console.log(
    //   ' -- errorInterceptor -- \n  authToken',
    //   authToken,
    //   'refreshToken',
    //   refreshToken,
    // );
    // return;
    try {
      getRefreshToken(authToken, refreshToken, signOut);
    } catch (err) {
      signOut(null, null, '/sign', true);
    }
  }
  // if (error.response != null && error.response.status === 401) {
  //   signOut(null, null, '/', true);
  // }
};

class httpClient {
  private axiosInstance: AxiosInstance;
  constructor() {
    //this.navigate = useNavigate();

    this.axiosInstance = axios.create({
      baseURL: config.baseURLApi,
    });
    this.axiosInstance.interceptors.request.use(requestInterceptor);
    this.axiosInstance.interceptors.response.use(
      (response) => {
        //return Promise.reject(new Error('test error'));
        // successInterceptor(response);
        return response;
      },
      (error: AxiosError) => {
        errorInterceptor(error);
        return Promise.reject(error);
      },
    );
  }

  logOut(status: number) {
    //if (status === 401) signOut();
  }

  async get(url: string, data = {}) {
    return await this.axiosInstance
      .get(url, { params: data })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        //console.log('error==', error);
        return Promise.reject(error);
      });
  }

  async post(url: string, data = {}, config = {}) {
    //console.log('config==', config);
    return await this.axiosInstance
      .post(url, data, config)
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        //console.log('error==', error);
        return Promise.reject(error);
      });
  }

  async delete(url: string, data = {}) {
    //console.log('config==', config);
    return await this.axiosInstance
      .delete(url, { data: { ...data } })
      .then((response) => Promise.resolve(response))
      .catch((error) => {
        //console.log('error==', error);
        return Promise.reject(error);
      });
  }

  // put(url, data = {}) {
  //   return this.axiosInstance
  //     .put(url, data)
  //     .then((response) => Promise.resolve(response))
  //     .catch((error) => Promise.reject(error));
  // }
}
const client = new httpClient();
export default client;
