import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { VisitsProvider } from '../../context/VisitsContext';
import Visits from './Visits';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.bgLight.one}`,
  },
  filters: {
    // marginTop: `-27px`,
    marginBottom: theme.spacing(1),
    //alignItems: "center"
  },
  backButton: {
    textTransform: 'none',
    fontSize: 22,
  },
}));

export default function VisitsPage() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { specificRecordId } = useParams();
  // const [selectedDoctor, handleDoctorsChange] = useState("");
  const [futureVisits, setFutureVisits] = useState(true);
  const handlefutureVisits = () => {
    setFutureVisits(!futureVisits);
  };

  return (
    <VisitsProvider>
      <Grid
        container
        spacing={3}
        className={classes.filters}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs>
          {specificRecordId == null && (
            <FormControlLabel
              className={classes.butFilters}
              control={
                <Switch
                  checked={futureVisits}
                  onChange={handlefutureVisits}
                  value="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography variant="h6">
                  {t('EVENTS_VISITS.FILTER_FUTURE')}
                </Typography>
              }
            />
          )}
        </Grid>
      </Grid>

      <Visits futureVisits={futureVisits} type="All" />
      <Grid
        container
        spacing={3}
        className={classes.filters}
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          {specificRecordId != null && (
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/app/events_and_visits"
            >
              {t('INVOICES.FILTER_ALL')}
            </Button>
          )}
        </Grid>
      </Grid>
    </VisitsProvider>
  );
}
