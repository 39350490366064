/* eslint-disable no-use-before-define */
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import isEmpty from '../../helpers';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function AutocompleteCommon({
  data,
  selectedDataId,
  handleDataChange,
  name,
  label,
  keyId,
  keyPrimary,
  keySecondary,
  keyTertiary,
  errors,
  textSecondary,
  textTertiary,
}) {
  const handleChange = (e, v) => {
    if (v != null && handleDataChange != null)
      handleDataChange(name, v[keyId]);
  };
  const { t } = useTranslation();
  return (
    !isEmpty(data) && (
      <Autocomplete
        //id="checkboxes-tags-demo"
        name={name}
        value={
          data.find((item) => item[keyId] === selectedDataId) || null
        }
        options={data}
        onChange={handleChange}
        // style={{ minWidth: 240 /* maxWidth: 310 */ }}
        defaultValue={null}
        isOptionEqualToValue={(option) =>
          option[keyId] === selectedDataId
        }
        getOptionLabel={(option) => option[keyPrimary]}
        renderOption={(props, option) => (
          <Grid container {...props}>
            <Grid item>{option[keyPrimary]}</Grid>
            {(keySecondary != null ||
              textSecondary != null ||
              keyTertiary != null ||
              textTertiary != null) && (
              <Grid item>
                {(keySecondary != null || textSecondary != null) && (
                  <Typography variant="body2">
                    {textSecondary} {option[keySecondary]}
                  </Typography>
                )}
                {(keyTertiary != null || textTertiary != null) && (
                  <Typography variant="body2">
                    {textTertiary} {option[keyTertiary]}
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
        )}
        noOptionsText={t('EMPTY_DATA.NO_OPTIONS')}
        renderInput={(params) => (
          <TextField
            {...params}
            margin="normal"
            label={label}
            fullWidth
            variant="outlined"
            error={errors != null && errors[name] != null}
            helperText={
              errors != null && errors[name] != null && errors[name]
            }
          />
        )}
      />
    )
  );
}
