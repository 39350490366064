// import React from 'react';
// import {
//   Home as HomeIcon,
//   CalendarToday as CalendarIcon,
//   Description as DescriptionIcon,
//   Chat as ChatIcon,
// } from '@mui/icons-material';

const structure = [
  {
    id: 0,
    t: 'HOME',
    link: 'home',
    // icon: <CalendarIcon />,
    defaultH1: false,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 1,
    t: 'EVENTS',
    link: 'events_and_visits',
    // icon: <CalendarIcon />,
    defaultH1: false,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 2,
    t: 'MED_CARD',
    tH1: 'MED_CARD_H1',
    link: 'mmk',
    // icon: <DescriptionIcon />,
    defaultH1: false,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 3,
    t: 'INVOICES',
    link: 'invoices',
    // icon: <DescriptionIcon />,
    defaultH1: false,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 4,
    t: 'DIRECTIONS',
    link: 'directions',
    // icon: <DescriptionIcon />,
    defaultH1: true,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 5,
    t: 'PRESCRIBED_DRUGS',
    tH1: 'PRESCRIBED_DRUGS_H1',
    link: 'drugs',
    // icon: <DescriptionIcon />,
    defaultH1: false,
    hasMainMenu: true,
    hasLeftMenu: false,
  },

  {
    id: 6,
    t: 'CLINIC_MAP',
    link: 'map',
    // icon: <DescriptionIcon />,
    defaultH1: true,
    hasMainMenu: true,
    hasLeftMenu: false,
  },
  {
    id: 7,
    t: 'APPOINTMENT',
    link: 'visit',
    // icon: <HomeIcon />,
    defaultH1: false,
    hasMainMenu: false,
    hasLeftMenu: false,
  },

  {
    id: 30,
    t: 'TIMELINE',
    link: 'extra/timeline',
    // icon: <ChatIcon />,
    defaultH1: true,
    hasMainMenu: false,
    hasLeftMenu: false,
  },

  {
    id: 40,
    t: 'PROFILE',
    link: 'profile',
    // icon: <ChatIcon />,
    hasMainMenu: false,
    hasLeftMenu: true,
  },
  {
    id: 50,
    t: 'USER_SESSIONS',
    link: 'sessions',
    // icon: <DevicesIcon />,
    hasMainMenu: false,
    hasLeftMenu: true,
  },
  // {
  //   id: 60,
  //   t: 'FAQ',
  //   link: 'faq',
  //   // icon: <DevicesIcon />,
  //   hasMainMenu: false,
  //   hasLeftMenu: true,
  // },
  // {
  //   id: 70,
  //   title: 'Возврат НДФЛ',
  //   link: 'calypsoForm/03003267-8894-4594-bb33-0f20d087ac84',
  //   hasMainMenu: true,
  //   hasLeftMenu: true,
  // },
  // {
  //   id: 71,
  //   title: 'Вызов врача на дом',
  //   link: 'calypsoForm/59d558fb-f00b-4845-8bd4-2f595ba8f250',
  //   hasMainMenu: true,
  //   hasLeftMenu: false,
  // },
  // {
  //   id: 72,
  //   title: 'Анкета',
  //   link: 'calypsoForm/c8e4b68b-43c4-4dde-aaf1-704ce9275d3f',
  //   hasMainMenu: false,
  //   hasLeftMenu: true,
  // },
  {
    id: 80,
    t: 'FEEDBACK',
    link: 'feedback',
    hasMainMenu: false,
    hasLeftMenu: true,
  },
];
export default structure;
