import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import { useInvoicesState } from '../../context/InvoicesContext';
import {
  getInvoiceById,
  getInvoicePaidLink,
  getInvoices,
} from '../../actions/invoices';

//import { getClinics } from "../../context/VisitContext";
import { useUserStateDispatch } from '../../context/UserContext';

import { makeStyles } from '@mui/styles';
//import Typography from "@mui/material/Typography";
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroller';
import InvoicesItem from './InvoicesItem';
import isEmpty from '../../helpers';
import IframePaid from './IframePaid';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
  dateToHL7,
} from '../../helpers/dateFormat';
import Modale from '../../components/Modals/Modale';

import { useTranslation } from 'react-i18next';
import Loading from '../../components/Loading';
import { Alert, Box, Button } from '@mui/material';
//import useInterval from '../../hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  montHeader: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

export default function Invoices() {
  const { t } = useTranslation();

  const {
    state: { invoices, paymentStatus, dateRange, local, paidData },
    setState,
  } = useInvoicesState();
  const { invoiceId, paramClinicId } = useParams();
  const {
    userState: {
      user: { mmkId, clinicId, lang },
      appInfo,
    },
  } = useUserStateDispatch();

  const openModalPaid = (item) => {
    const needPaid = !item.isPaid && item.canBePaid;
    if (!paidData.modalPaid && needPaid) {
      getInvoicePaidLink({
        setState,
        mmkId,
        clinicId: item.clinicId,
        invoiceId: item.id,
      });
    }
  };
  const closeModal = () => {
    if (paidData.modalPaid) {
      setState({
        type: 'RESET_PAID_DATA',
      });
      getInvoiceById({
        setState,
        invoiceId: paidData.invoiceId,
        mmkId,
        clinicId: paidData.clinicId,
      });
    }
  };

  useEffect(() => {
    setState({
      type: 'REST_INVOICES',
    });
    if (invoiceId != null && paramClinicId != null) {
      getInvoiceById({
        setState,
        invoiceId,
        mmkId,
        clinicId: paramClinicId,
      });
    }

    if (invoiceId == null) {
      getInvoices({
        setState,
        mmkId,
        clinicId,
        paymentStatus,
        dateFrom:
          dateRange[0] != null ? dateToHL7(dateRange[0]) : null,
        maxDate:
          dateRange[1] != null ? dateToHL7(dateRange[1]) : null,
        local,
      });
    }
  }, [mmkId, clinicId, paymentStatus, dateRange, invoiceId, local]);

  useEffect(() => {
    if (
      paidingItem != null &&
      appInfo.paidIsBowserWin &&
      paidData.modalPaid
    ) {
      window.winPaid = window.open(
        paidData.paidLink,
        'Paid',
        'width=800,height=600,left=240,top=180',
      );
      const winOpenTimer = setInterval(function () {
        if (window.winPaid == null || window.winPaid.closed) {
          //  console.log('!!!!!!!!   closeModal   !!!!!!!!');
          closeModal();
          clearInterval(winOpenTimer);
        } else {
          window.winPaid.blur();
          window.winPaid.focus();
        }
      }, 1500);
    }
  }, [paidData.modalPaid]);

  const classes = useStyles();

  const paidingItem =
    !isEmpty(invoices.data) && paidData.invoiceId != null
      ? invoices.data.find((item) => item.id === paidData.invoiceId)
      : null;

  const items = invoices.data.map((item, inx) => {
    const monthYearCurr = HL7ToDateToSrtDate(
      item.date,
      lang,
      'MMM YYYY',
      true,
    );
    const monthYearPrev = !isEmpty(invoices.data[inx - 1])
      ? HL7ToDateToSrtDate(
          invoices.data[inx - 1].date,
          lang,
          'MMM YYYY',
          true,
        )
      : '';

    return (
      <React.Fragment key={item.id}>
        {monthYearCurr !== monthYearPrev && (
          <Typography variant="h5" className={classes.montHeader}>
            {monthYearCurr}
          </Typography>
        )}
        <InvoicesItem
          invoice={item}
          invoiceId={invoiceId}
          paramClinicId={paramClinicId}
          openModalPaid={openModalPaid}
          paidData={paidData}
        />
      </React.Fragment>
    );
  });

  const onCloseModalPaid = () => {
    getInvoiceById({
      setState,
      invoiceId: paidData.id,
      mmkId,
      clinicId: paidData.clinicId,
    });
  };
  // useInterval(
  //   () => {
  //     const forceCloseModal = localStorage.getItem('paymentModale');
  //     //console.log('forceCloseModal', forceCloseModal);
  //     if (forceCloseModal === 'close') {
  //       localStorage.removeItem('paymentModale');
  //       getInvoiceById({
  //         setState,
  //         invoiceId: paidData.id,
  //         mmkId,
  //         clinicId: paidData.clinicId,
  //       });
  //       setPaidData({
  //         ...paidData,
  //         modalPaid: false,
  //       });
  //     }
  //   },
  //   paidData.modalPaid,
  //   700,
  // );

  return (
    <>
      {paidingItem != null &&
        !appInfo.paidIsBowserWin &&
        paidData.modalPaid && (
          <Modale
            onClose={onCloseModalPaid}
            open={paidData.modalPaid}
            toggleModal={closeModal}
            title={`${t('INVOICES.BILL_PAYMENT')} №${
              paidingItem.number
            } ${HL7ToDateToSrtDate(paidingItem.date, lang)} 
              ${HL7ToDateToTime(paidingItem.date)}`}
            height={720}
            isScrollHide={true}
            maxWidth="md"
          >
            <IframePaid />
          </Modale>
        )}

      {!invoices.isLoaded ? (
        <Loading />
      ) : invoices.isLoaded && invoices.responseError != null ? (
        <Alert severity="error">
          <Typography variant="h6" component="p">
            {invoices.responseError}
          </Typography>
        </Alert>
      ) : isEmpty(items) ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_INVOICES')}
          </Typography>
        </Alert>
      ) : invoiceId == null ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={() =>
            invoices.pageIndex > 0 &&
            getInvoices({
              setState,
              mmkId,
              clinicId,
              paymentStatus,
              dateFrom:
                dateRange[0] != null ? dateToHL7(dateRange[0]) : null,
              maxDate:
                dateRange[1] != null ? dateToHL7(dateRange[1]) : null,
              pageIndex: invoices.pageIndex,
              local,
            })
          }
          hasMore={invoices.hasMore}
          loader={<Loading key={0} />}
        >
          {items}
        </InfiniteScroll>
      ) : (
        <>
          {items}
          <Box mt={3} textAlign="center">
            <Button
              variant="contained"
              color="secondary"
              component={Link}
              to="/app/invoices"
            >
              {t('INVOICES.FILTER_ALL')}
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
