import React, { useState } from 'react';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginUser } from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validateSignIn from '../../pages/sign_in_up/validationSignIn';

import SignInForm from '../../components/Forms/SignInForm';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLanguageValue } from '../../context/LanguageContext';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ appInfo, valsTab, setValsTab, setActiveTabId }) {
  // global
  const { t } = useTranslation();

  const { userDispatch } = useUserStateDispatch();

  const {
    languageState: { language },
  } = useLanguageValue();

  const navigate = useNavigate();

  // local

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setValsTab,
      valsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const {
    values,
    errors,
    setValues,
    setErrors,
    handleChange,
    handleSubmit,
    handlePhoneChange,
  } = useForm(login, validateSignIn, appInfo);

  return (
    <SignInForm
      values={values}
      errors={errors}
      setValues={setValues}
      setErrors={setErrors}
      validate={validateSignIn}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      valsTab={valsTab}
      setValsTab={setValsTab}
      appInfo={appInfo}
      handlePhoneChange={handlePhoneChange}
    />
  );
}

export default SignIn;
