import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserStateDispatch } from '../../context/UserContext';
import { useVisitsState } from '../../context/VisitsContext';
import {
  getVisits,
  cancelVisit,
  sendICameVisit,
  createIvoiceByVisit,
  getChatInfo,
} from '../../actions/visits';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Alert as AlertBase } from '@mui/material';

import VisitsItem from './VisitsItem';
import InfiniteScroll from 'react-infinite-scroller';
import Confirm from '../../components/Modals/ConfirmDialogSlide';
import Alert from '../../components/Modals/AlertDialogSlide';
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import Loading from '../../components/Loading';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import useInterval from '../../hooks/useInterval';

const useStyles = makeStyles((theme) => ({
  marginMid: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  montHeader: {
    '&::first-letter': {
      textTransform: 'uppercase',
    },
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const InfinitVisits = ({
  visits,
  setState,
  futureVisits,
  includeCancelled,
  mmkId,
  clinicId,
  type,
  specOrName,
  items,
  canceledVisit,
  iCameVisit,
  createInvoice,
}) =>
  useMemo(
    () => (
      <InfiniteScroll
        pageStart={0}
        loadMore={() =>
          visits.isLoaded &&
          visits.startIndex > 0 &&
          getVisits({
            setState,
            startIndex: visits.startIndex,
            count: visits.count,
            futureVisits,
            includeCancelled,
            mmkId,
            clinicId,
            type,
            filterString: specOrName,
          })
        }
        hasMore={visits.hasMore}
        loader={<Loading key={0} />}
      >
        {items}
      </InfiniteScroll>
    ),
    [
      mmkId,
      visits.data.length,
      canceledVisit,
      iCameVisit,
      createInvoice,
    ],
  );

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function Visits({
  setExistVisits,
  isExistPromo,
  toggleModalChatVisit,
}) {
  const limit = 5;
  const isHero = setExistVisits != null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const {
    state: {
      visits,
      canceledVisit,
      iCameVisit,
      createInvoice,

      specOrName,
      futureVisits,
      includeCancelled,
      type,
    },
    setState,
  } = useVisitsState();

  const {
    userState: {
      user: { mmkId, clinicId, lang },
      appInfo,
    },
  } = useUserStateDispatch();

  const { specificRecordId, paramClinicId } = useParams();

  useEffect(() => {
    setState({
      type: 'RESET_VISITS',
    });

    getVisits({
      setState,
      setExistVisits: isHero ? setExistVisits : null,
      startIndex: 0,
      count: limit != null ? limit : visits.count,
      futureVisits,
      includeCancelled,
      mmkId,
      clinicId: paramClinicId != null ? paramClinicId : clinicId,
      type,
      filterString: specOrName,
      specificRecordId:
        specificRecordId != null ? specificRecordId : null,
    });
  }, [
    futureVisits,
    includeCancelled,
    type,
    specOrName,
    mmkId,
    clinicId,
    specificRecordId,
    paramClinicId,
  ]);

  const MonthYear = ({ item, inx }) => {
    if (item?.startDateTime == null) return;
    const monthYearCurr = HL7ToDateToSrtDate(
      item.startDateTime,
      lang,
      'MMMM YYYY',
      true,
    );
    const monthYearPrev = !isEmpty(visits.data[inx - 1])
      ? HL7ToDateToSrtDate(
          visits.data[inx - 1].startDateTime,
          lang,
          'MMMM YYYY',
          true,
        )
      : '';
    return (
      monthYearCurr !== monthYearPrev && (
        <Typography variant="h5" className={classes.montHeader}>
          {monthYearCurr}
        </Typography>
      )
    );
  };

  const items = visits.data.map((visit, inx) => {
    // const chat = chatRooms.find(
    //   (item) => item?.planningId === visit?.planningId,
    // );

    return !isHero ? (
      <React.Fragment key={visit.id}>
        <MonthYear item={visit} inx={inx} />
        <VisitsItem
          visit={visit}
          mmkId={mmkId}
          setState={setState}
          showClinic={true}
          toggleModalChatVisit={toggleModalChatVisit}
          createInvoice={createInvoice}
        />
      </React.Fragment>
    ) : (
      <Grid item xs={12} sm={isExistPromo ? 12 : 6} key={visit.id}>
        <VisitsItem
          visit={visit}
          mmkId={mmkId}
          setState={setState}
          showClinic={false}
          toggleModalChatVisit={toggleModalChatVisit}
          createInvoice={createInvoice}
        />
      </Grid>
    );
  });

  useEffect(() => {
    // осуществляется генерация счетов
    // дернуть еще раз
    //console.log('createInvoice\n', createInvoice);
    if (
      !isEmpty(createInvoice?.data) &&
      createInvoice?.data?.status === 2
    ) {
      setTimeout(() => {
        createIvoiceByVisit({
          setState,
          planningId: createInvoice.planningId,
          mmkId: createInvoice.mmkId,
          clinicId: createInvoice.clinicId,
          id: createInvoice.id,
          futureVisits,
          includeCancelled,
          type,
          filterString: specOrName,
        });
      }, 15000); // 15 sec
    }
  }, [createInvoice?.data]);

  const redirectToInvoice = () => {
    if (
      createInvoice != null &&
      !isEmpty(createInvoice?.data) &&
      createInvoice?.data?.status === 3 &&
      createInvoice?.data?.invoices[0]?.invoiceId != null
    ) {
      navigate(
        `/app/invoices/${createInvoice.data.invoices[0].invoiceId}/${createInvoice.clinicId}`,
      );
    }
    return;
  };

  useInterval(
    () => {
      /** REFRESH chatInfo */
      visits.data.forEach((item) => {
        if (item?.isOnlineConsultation) {
          //  console.log('item', item);
          getChatInfo({
            setState,
            visitId: item.id,
            mmkId,
            clinicId: item.clinicId,
          });
        }
      });
    },
    appInfo.isChatEnabled &&
      !isEmpty(visits.data) &&
      visits.data.some((item) => item?.isOnlineConsultation),
    10000,
  );

  return (
    <>
      {/* ---------- canceledVisit modals ------------ */}
      {canceledVisit.isConfirm && (
        <Confirm
          isOpen={true}
          setState={setState}
          typeReset="CANCEL_VISIT_REST"
          contentText={t('VISIT.CONFIRM_CANCEL')}
          callback={() =>
            cancelVisit({
              setState,
              planningId: canceledVisit.planningId,
              mmkId: canceledVisit.mmkId,
              clinicId: canceledVisit.clinicId,
              id: canceledVisit.id,
            })
          }
        />
      )}
      {canceledVisit.isSubmited && (
        <Alert
          state={canceledVisit}
          setState={setState}
          typeRset="CANCEL_VISIT_REST"
          isOpen={true}
          contentTextOk={t('EVENTS_VISITS.VISIT_CANCELED')}
        />
      )}
      {/* ---------- iCameVisit modals ------------ */}
      {iCameVisit.isConfirm && (
        <Confirm
          isOpen={true}
          setState={setState}
          typeReset="ICAME_VISIT_REST"
          contentText={t('VISIT.CONFIRM_ICAME')}
          callback={() =>
            sendICameVisit({
              setState,
              clinicId: iCameVisit.clinicId,
              visitId: iCameVisit.id,
            })
          }
        />
      )}
      {iCameVisit.isSubmited && (
        <Alert
          state={iCameVisit}
          setState={setState}
          typeRset="ICAME_VISIT_REST"
          isOpen={true}
          contentTextOk={t('EVENTS_VISITS.ICAME_VISIT_OK')}
          //doCallbackWhenOk={() => refreshVisits()}
        />
      )}

      {/* ---------- createInvoice modals ------------ */}
      {createInvoice.isConfirm && (
        <Confirm
          isOpen={true}
          setState={setState}
          typeReset="CREATE_INVOICE_REST"
          contentText={t('VISIT.CONFIRM_CREATE_INVOICE')}
          callback={() =>
            createIvoiceByVisit({
              setState,
              planningId: createInvoice.planningId,
              mmkId,
              clinicId: createInvoice.clinicId,
              id: createInvoice.id,
              futureVisits,
              includeCancelled,
              type,
              filterString: specOrName,
            })
          }
        />
      )}
      {createInvoice.isSubmited && (
        <Alert
          state={createInvoice}
          setState={setState}
          typeRset={
            createInvoice?.data?.status === 3
              ? 'CREATE_INVOICE_REST'
              : null
          }
          isOpen={true}
          contentTextOk={t('EVENTS_VISITS.INVOICE_CREATED')}
          doCallbackWhenOk={redirectToInvoice}
          buttonTitle={t('EVENTS_VISITS.PAY_INVOICE')}
          showLoading={true}
        />
      )}
      {!visits.isLoaded && isEmpty(visits.data) && <Loading />}
      {visits.isLoaded && visits.responseError != null ? (
        <AlertBase severity="error">
          <Typography variant="h6" component="p">
            {visits.responseError}
          </Typography>
        </AlertBase>
      ) : visits.isLoaded && isEmpty(visits.data) ? (
        <AlertBase severity="warning">
          <Typography variant="h6" component="p">
            {t('EMPTY_DATA.NO_EVENTS')}
          </Typography>
        </AlertBase>
      ) : visits.isLoaded &&
        isHero &&
        !isEmpty(visits.data) /** for HOME  */ ? (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3">{t('HOME.BRANCH4')}</Typography>
          </Grid>
          {items}
        </Grid>
      ) : visits.isLoaded &&
        specificRecordId != null &&
        !isEmpty(visits.data) ? (
        items
      ) : !isEmpty(visits.data) ? (
        <InfinitVisits
          visits={visits}
          setState={setState}
          futureVisits={futureVisits}
          includeCancelled={includeCancelled}
          mmkId={mmkId}
          clinicId={clinicId}
          type={type}
          specOrName={specOrName}
          items={items}
          canceledVisit={canceledVisit.data}
          iCameVisit={iCameVisit.data}
          createInvoice={createInvoice.data}
        />
      ) : null}
    </>
  );
}
