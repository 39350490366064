import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  TextField as Input,
  Collapse as Fade,
  Alert,
  Grid,
} from '@mui/material';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { confirmLogin } from '../../actions/user';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignInCode';

//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 46,
    textTransform: 'none',
    minWidth: 200,
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  formButtons: {
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignInConfirm({ valsTab, setValsTab }) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: {
      user: { lang },
      appInfo,
      serverResponse,
      isLoaded,
    },
    userDispatch,
  } = useUserStateDispatch();

  const [counter, setCounter] = React.useState(
    valsTab.confirmationCodeInfo?.lifeTime,
  );
  const confirm = () =>
    confirmLogin(
      userDispatch,
      values.code,
      navigate,
      lang,
      appInfo.isAnonymousChildrenEnabled,
    );

  const { values, errors, handleChange, handleSubmit } = useForm(
    confirm,
    validate,
    appInfo,
  );
  // Counter
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  // Set Counter
  // useEffect(() => {
  //   if (valsTab.confirmationCodeInfo?.lifeTime != null)
  //     setCounter(valsTab.confirmationCodeInfo?.lifeTime);
  //   console.log(valsTab.confirmationCodeInfo);
  // }, [valsTab.confirmationCodeInfo?.lifeTime]);

  return !isLoaded ? (
    <CircularProgress size={26} className={classes.loginLoader} />
  ) : (
    <>
      <Fade
        in={!!serverResponse}
        style={
          serverResponse == null
            ? { display: 'none' }
            : { display: 'flex' }
        }
      >
        <Alert severity="error" className={classes.errorMessage}>
          <Typography variant="h6">{serverResponse}</Typography>
        </Alert>
      </Fade>
      <Input
        name="code"
        variant="outlined"
        value={values.code}
        onChange={handleChange}
        margin="normal"
        label={t('COMPONENT.CONFIRM_CODE')}
        type="code"
        fullWidth
        required
        error={errors?.code != null}
        helperText={errors?.code != null && errors?.code}
      />
      <Grid container mt={0.5} mb={3} alignItems="center" spacing={3}>
        <Grid item xs={12} md={6}>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={isEmpty(values.code) || counter === 0}
            onClick={handleSubmit}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit();
              }
            }}
          >
            {t('SIGN.IN_BUTTON')}
          </Button>
        </Grid>
        <Grid item xs={12} md={6}>
          {counter > 0 ? (
            <Typography variant="body1">
              {t('SIGN.IN_BY_QR_LIFETIME', { sec: counter })}
            </Typography>
          ) : (
            <Button
              color="primary"
              onClick={() => {
                delete valsTab?.confirmationCodeInfo;
                delete valsTab?.warningNote;
                userDispatch({ type: 'LOADED' });
                setValsTab({
                  ...valsTab,
                });
              }}
            >
              {t('COMPONENT.BUTTON_GET_CONFIRM_CODE')}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default SignInConfirm;
