import React, { useEffect } from 'react';

import SignUpForm from '../../components/Forms/SignUpForm';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUp({
  valsTab,

  setActiveTabId,

  values,
  errors,
  handleChange,
  handleSubmit,
  setValues,
  setErrors,

  validateSignUp,
  handlePhoneChange,
}) {
  const { t } = useTranslation();
  const {
    userDispatch,
    userState: { appInfo },
  } = useUserStateDispatch();

  useEffect(() => {
    const vals = {
      ...values,
      login: ['phone', 'both'].includes(appInfo.authIdentifiers)
        ? values?.phone
        : null,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));

    // eslint-disable-next-line
  }, []);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;
    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  return (
    <>
      <SignUpForm
        values={values}
        handleChange={handleChange}
        setValues={setValues}
        setErrors={setErrors}
        errors={errors}
        validate={validateSignUp}
        handleSubmit={handleSubmit}
        handleDateChange={handleDateChange}
        handleChangeGender={handleChangeGender}
        handlePhoneChange={handlePhoneChange}
        setActiveTabId={setActiveTabId}
      />
      <Button
        color="primary"
        variant="text"
        onClick={() => {
          setValues({});
          userDispatch({ type: 'LOADED' });
          setActiveTabId(valsTab?.activeTabIdPrev ?? 100);
        }}
        style={{ width: '100%', marginTop: 24 }}
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </>
  );
}

export default SignUp;
