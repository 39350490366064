import React from 'react';

import SignInForm from '../../components/Forms/SignInForm';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({
  setActiveTabId,
  values,
  errors,
  setValues,
  setErrors,
  validate,
  handleChange,
  handleSubmit,
  appInfo,
  valsTab,
  setValsTab,
  handlePhoneChange,
}) {
  const { t } = useTranslation();

  return (
    <>
      <SignInForm
        values={values}
        errors={errors}
        setValues={setValues}
        setErrors={setErrors}
        validate={validate}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        valsTab={valsTab}
        setValsTab={setValsTab}
        appInfo={appInfo}
        handlePhoneChange={handlePhoneChange}
      />
      <Button
        color="primary"
        variant="text"
        onClick={() => setActiveTabId(100)}
        style={{ width: '100%', marginTop: 24 }}
      >
        {t('COMPONENT.BACK_ONE_STEP')}
      </Button>
    </>
  );
}

export default SignIn;
