import React, { useCallback, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ErrorIcon from '@mui/icons-material/Error';
import PaymentIcon from '@mui/icons-material/Payment';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

import classnames from 'classnames';
import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
} from '../../helpers/dateFormat';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Badge,
  Alert,
  Box,
  useMediaQuery,
} from '@mui/material';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

// styles
import useStyles from './visitsItemStyle';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import { getVisitKinds } from '../../actions/visits';
import isEmpty from '../../helpers';
import { ReactComponent as OnlineIcon } from '../../images/icons/online.svg';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function VisitsItem({
  visit,
  mmkId,
  showClinic,
  setState,
  toggleModalChatVisit,
  createInvoice,
}) {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const {
    userState: {
      clinics,
      user: { lang, isAnonymous },
      appInfo,
    },
  } = useUserStateDispatch();
  const classes = useStyles();

  const handleCancelVisit = useCallback(() => {
    setState({
      type: 'CANCEL_VISIT_CONFIRM',
      payload: {
        planningId: visit.planningId,
        clinicId: visit.clinicId,
        id: visit.id,
        mmkId,
      },
    });
  }, []);

  const handleICameVisit = useCallback(() => {
    setState({
      type: 'ICAME_VISIT_CONFIRM',
      payload: {
        clinicId: visit.clinicId,
        id: visit.id,
      },
    });
  }, []);

  const handleCreateInvoice = useCallback(() => {
    if (visit?.fmInvoiceId != null)
      navigate(
        `/app/invoices/${visit?.fmInvoiceId}/${visit.clinicId}`,
      );
    else
      setState({
        type: 'CONFIRM_CREATE_PAYMENT',
        payload: {
          planningId: visit.planningId,
          clinicId: visit.clinicId,
          id: visit.id,
          mmkId,
        },
      });
  }, [visit?.fmInvoiceId]);

  // const fio =
  //   visit.doctor?.lastName || visit.doctor?.firstName
  //     ? `${visit.doctor?.lastName} ${visit.doctor?.firstName} ${
  //         visit.doctor?.middleName ?? ''
  //       }`
  //     : visit.doctor?.plSubjName;

  const fio = visit.doctor?.plSubjName;

  const specName =
    visit.doctor?.specializationInfo || visit?.doctorSpecialization;

  const clinic = clinics.find((it) => it.id === visit.clinicId);

  // console.log('createInvoice.id', createInvoice.id, visit.id);
  const showPayVisitButton = () => {
    //values in appInfo.showPayVisitButton online,offline,all,none
    if (appInfo.showPayVisitButton === 'all') return true;
    if (
      appInfo.showPayVisitButton === 'online' &&
      visit?.isOnlineConsultation
    )
      return true;
    if (
      appInfo.showPayVisitButton === 'offline' &&
      !visit?.isOnlineConsultation
    )
      return true;
    else return false;
  };

  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
    if (isExpanded)
      getVisitKinds({
        setState,
        id,
        clinicId: visit.clinicId,
        plExamId: visit.plExamId,
      });
  };

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const isCancelled =
    !visit.isCancelled &&
    !visit.isPaid &&
    visit.isFutureVisit &&
    !visit?.chatInfo?.conferenceId;
  const ButtonRight = () => (
    <>
      {visit.canAcceptICameMessage && (
        <Button
          color="info"
          data-testid="but-i-came"
          variant="outlined"
          className={classes.payBut}
          onClick={() => handleICameVisit()}
          sx={{ mb: 3 }}
        >
          <CheckCircleIcon
            className={classes.iconButtCreateInvoice}
          />
          {t('COMPONENT.BUT_ICAME')}
        </Button>
      )}
      {isCancelled && (
        <Button
          data-testid="but-cancelled"
          variant="text"
          className={classes.cancelled}
          onClick={() => handleCancelVisit()}
          sx={{ mb: 3 }}
        >
          <ErrorIcon className={classes.iconButt} />
          {t('COMPONENT.BUT_CANCEL')}
        </Button>
      )}
    </>
  );

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      mb={showClinic ? 3 : 0}
    >
      <Grid item xs={12} sm={12} md={2}>
        <Grid
          container
          classes={{
            root: classes.borderRadiusLeft,
            container: classnames(
              classes.item,
              classes.itemHeight100,
              visit?.isOnlineConsultation
                ? classes.itemConf
                : classes.itemVisit,
            ),
          }}
          justifyContent="center"
          alignItems="center"
          className={
            visit?.isOnlineConsultation &&
            !isMobile &&
            classes.rectangle
          }
        >
          <Grid item xs>
            <Typography variant="h4" align="center">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(
                  visit.startDateTime,
                  lang,
                  'DD',
                )}{' '}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {visit.startDateTime &&
                HL7ToDateToSrtDate(visit.startDateTime, lang, 'MMMM')}
            </Typography>
          </Grid>
        </Grid>

        {visit?.isOnlineConsultation && !isMobile && (
          <OnlineIcon className={classes.rectangleIcon} />
        )}
      </Grid>

      {!isMobile && (
        <Grid>
          <Grid
            container
            className={classes.itemHeight100}
            justifyContent="center"
            alignItems="center"
          >
            <Grid className={classes.itemGep}></Grid>
          </Grid>
        </Grid>
      )}

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            visit?.isOnlineConsultation
              ? classes.itemConf
              : classes.itemVisit,
          ),
        }}
        xs={12}
        sm={12}
        md
      >
        {visit?.isOnlineConsultation ? (
          <Typography variant="h6" mb={1}>
            {t('EVENTS_VISITS.TITLE_ONLINE_CONF')}

            {visit?.chatInfo != null && (
              <Badge
                badgeContent={visit?.chatInfo?.unreadCount}
                color="warning"
                className={classes.unreadMessagesCount}
              />
            )}
          </Typography>
        ) : (
          <Typography variant="h6" mb={1}>
            {visit.isCancelled
              ? t('EVENTS_VISITS.TITLE_VISIT_CANCELED')
              : t('EVENTS_VISITS.TITLE_VISIT')}
          </Typography>
        )}

        <Typography variant="body1">
          {fio} {specName}
        </Typography>

        {visit.plExamName && (
          <Typography variant="body1" mb={1}>
            {t('VISIT.TYPE')}: {visit.plExamName}
          </Typography>
        )}
        {visit.plExamId != null && !visit.plExamName && (
          <Accordion
            defaultExpanded={false}
            classes={{
              root: classes.Accordion,
            }}
            expanded={expanded === `panel${visit.id}`}
            onChange={handleChange(visit.id)}
          >
            <AccordionSummary
              classes={{
                root: classes.Accordion,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              {t('VISIT.TYPE')}
            </AccordionSummary>
            <AccordionDetails>
              {!visit.visitKinds?.isLoaded && (
                <CircularProgress size={26} />
              )}
              {visit.visitKinds?.isLoaded &&
                visit.visitKinds?.responseRecordError != null && (
                  <Alert severity="error">
                    <Typography variant="h6" component="p">
                      {visit.visitKinds?.responseRecordError}
                    </Typography>
                  </Alert>
                )}

              {visit.visitKinds?.isLoaded &&
                !isEmpty(visit.visitKinds?.data?.plExamInfo) &&
                visit.visitKinds.data.plExamInfo.map((item) => {
                  return (
                    <Typography
                      variant="body1"
                      component="p"
                      key={item.plExamId}
                    >
                      {item.name}
                    </Typography>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        )}

        {visit.exam.beforeExamDescription != null && (
          <Alert
            severity="success"
            icon={<InfoIcon sx={{ color: '#FA9907' }} />}
          >
            <Typography variant="h6">
              {visit.exam?.beforeExamDescription}
            </Typography>
          </Alert>
        )}

        <Typography variant="body2" mt={1.5}>
          <WatchLaterIcon
            className={
              !visit?.isOnlineConsultation
                ? classes.offLineIcon
                : classes.onLineIcon
            }
          />
          {visit.startDateTime &&
            HL7ToDateToTime(visit.startDateTime)}{' '}
          — {visit.endDateTime && HL7ToDateToTime(visit.endDateTime)}
        </Typography>

        {!visit?.isOnlineConsultation ? (
          <>
            <Typography variant="body2">
              <LocationOnIcon className={classes.offLineIcon} />
              {clinic?.address}
            </Typography>
            <Typography variant="body2">
              <LocalHospitalIcon className={classes.offLineIcon} />
              {clinic?.name}{' '}
            </Typography>

            {appInfo.showVisitCabinet && visit?.cabinet && (
              <Typography variant="body2" ml={2.7}>
                <Typography variant="subtitle2" component="span">
                  {t('VISIT.CABINET')}:{' '}
                </Typography>

                {visit?.cabinet}
              </Typography>
            )}
          </>
        ) : (
          <Typography variant="body2">
            <HeadsetMicIcon className={classes.onLineIcon} />
            Online
          </Typography>
        )}

        <Box mt={3} display="flex" alignItems="center">
          {visit?.isOnlineConsultation && (
            <Button
              data-testid="but-chat"
              color="primary"
              variant="contained"
              onClick={() => toggleModalChatVisit(visit)}
              sx={{ mr: 3 }}
            >
              {t('HOME.START_CHAT')}
            </Button>
          )}

          {!isAnonymous &&
            !visit.isCancelled &&
            !visit.isPaid &&
            showPayVisitButton() &&
            (createInvoice?.id === visit.id &&
            createInvoice?.data?.status === 2 ? (
              <LoadingButton
                color="success"
                variant="contained"
                className={classes.payBut}
                onClick={() => handleCreateInvoice()}
                loading={true}
                loadingPosition="end"
              >
                <PaymentIcon
                  className={classes.iconButtCreateInvoice}
                />{' '}
                {t('VISIT.LOADING')}
              </LoadingButton>
            ) : (
              <Button
                color="info"
                data-testid="but-create-invoice"
                variant="outlined"
                className={classes.payBut}
                onClick={() => handleCreateInvoice()}
              >
                <PaymentIcon
                  className={classes.iconButtCreateInvoice}
                />
                {t('VISIT.BUT_CREATE_INVOICE')}
              </Button>
            ))}
        </Box>
      </Grid>

      {showClinic && (
        <Grid
          item
          classes={{
            root: classnames(
              classes.item,
              visit?.isOnlineConsultation
                ? classes.itemConf
                : classes.itemVisit,
              classes.borderRadiusRight,
            ),
          }}
          xs={12}
          sm={12}
          md={2}
          textAlign="center"
        >
          <ButtonRight />
        </Grid>
      )}
    </Grid>
  );
}
