import React, { useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
//import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
//import StarBorderIcon from '@mui/icons-material/StarBorder';
import config from '../../config';
import isEmpty from '../../helpers';
import AvatarOnOffLine from '../../components/UserAvatar/AvatarOnOffLine';
import LocationOnIcon from '@mui/icons-material/LocationOn';
//import FormHelperText from '@mui/material/FormHelperText';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../helpers';
import { getDoctorsTimeSlots } from '../../actions/visit';

import { useUserStateDispatch } from '../../context/UserContext';
import { Box, useMediaQuery } from '@mui/material';
import SelectVisitKind from './SelectVisitKind';
import DoctorSlots from './DoctorSlots';
import { useLanguageValue } from '../../context/LanguageContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    padding: theme.spacing(2),
    height: '100%',
    marginBottom: theme.spacing(3),
  },

  locationOnIcon: {
    fontSize: '1.2rem',
    top: 3,
    position: 'relative',
  },

  btnDoc: {
    padding: `0  0 ${theme.spacing(1)} 0`,
    textAlign: 'left',
  },

  favIcon: {
    color: `${theme.palette.primary.main} !important`,
    background: theme.palette.bgLight.one,
    borderRadius: '50%',
    //opacity: '0.4',
    //boxShadow: "0px 2px 5px rgba(245, 112, 105, 0.461334)",
  },

  favIconActive: {
    color: `${theme.palette.warning.main} !important`,
    //boxShadow: "0px 2px 5px rgba(245, 112, 105, 0.461334)",
  },
  btnRating: { padding: 0 },

  checkIconButton: {
    background: theme.palette.bgLight.one,
    boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',
    marginTop: 6,
    marginLeft: 6,
    '&.MuiSvgIcon-root': {
      textTransform: 'none',
      background: theme.palette.bgLight.one,
      boxShadow: '0px 2px 35px rgb(78 72 190 / 8%)',

      '&:active': {
        background: theme.palette.bgLight.one,
      },
    },
  },
}));
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const RatingReder = ({ rating }) => (
  <Rating
    value={rating ?? null}
    size="small"
    //emptyIcon={<StarBorderIcon fontSize="inherit" />}
    readOnly
  />
);

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorCard({
  itemId,
  visitDate,
  doctor,
  clinic,
  timeSlotsIsLoaded,
  timeSlots,
  selectedVisitKindId,
  defaultVisitKind,
  visitKinds,
  isOnline,
  toggleModalCreateVisit,
  toggleModalDoc,
  //  gotoDoc,
  setState,

  review,
  toggleModalRating,
  toggleFav,
  cachedTimeSlots,

  setModaleInfo,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  //console.log('visitKinds', visitKinds);
  const {
    userState: { isAuthenticated, appInfo },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();

  const [visitKind, setVisitKind] = useState(defaultVisitKind);

  // const fio =
  //   doctor.lastName || doctor.firstName
  //     ? `${doctor.lastName} ${doctor.firstName} ${
  //         doctor.middleName ?? ''
  //       }`
  //     : doctor.plSubjName;

  const fio = doctor.plSubjName;

  const image = doctor.photoUrl
    ? `${config.baseURLApi}${doctor.photoUrl}&appCode=${config.APP_CODE}&imageSize=small`
    : null;

  const ButtonFav = () =>
    isAuthenticated && (
      <IconButton onClick={() => toggleFav(doctor, review)}>
        {review != null && review?.isFavorite ? (
          <FavoriteIcon className={classes.favIconActive} />
        ) : (
          <FavoriteBorderIcon className={classes.favIcon} />
        )}
      </IconButton>
    );

  const ButtonRating = () =>
    appInfo.showDoctorsRating && isAuthenticated ? (
      <Button
        className={classes.btnRating}
        onClick={() => toggleModalRating(doctor, visitKind, review)}
      >
        <RatingReder rating={doctor?.rating} />
      </Button>
    ) : appInfo.showDoctorsRating ? (
      <RatingReder rating={doctor?.rating} />
    ) : null;

  const handleVisitKindChange = useCallback(
    (value) => {
      const newVisitKind =
        visitKinds.find((item) => item.id === value) ||
        defaultVisitKind;
      const consultationType = isOnline ? 'Online' : 'Offline';

      setVisitKind(newVisitKind);
      getDoctorsTimeSlots({
        setState,
        itemId,
        startDate: visitDate,
        clinicId: clinic.id,
        doctorId: doctor.id,
        plSubjId: doctor.plSubjId,
        visitKindId: newVisitKind.id,
        cached: cachedTimeSlots,
        consultationType,
      });
    },

    [
      isOnline,
      visitDate,
      doctor.id,
      doctor.plSubjId,
      cachedTimeSlots,
    ],
  );

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );

  const titleSpecializationName =
    appInfo?.usePlExGrWebSpecializations &&
    visitKind?.specializationName
      ? visitKind?.specializationName
      : doctor.specializationInfo;

  return useMemo(
    () => (
      <>
        <Card className={classes.paper} data-testid="doctor-card">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2} wrap="nowrap">
                <Grid item>
                  <AvatarOnOffLine
                    specName={fio}
                    image={image}
                    isOnline={isOnline}
                  />
                  {appInfo.showFavoriteDoctors && (
                    <div style={{ position: 'absolute' }}>
                      <div
                        style={{
                          position: 'relative',
                          top: -38,
                          left: 45,
                        }}
                      >
                        <ButtonFav />
                      </div>
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="text"
                    className={classes.btnDoc}
                    onClick={() =>
                      toggleModalDoc(
                        doctor,
                        visitKind,
                        visitKinds,
                        isOnline,
                      )
                    }
                  >
                    {fio}
                  </Button>
                  <Box
                    display={{
                      sm: 'block',
                      md: 'flex',
                    }}
                  >
                    <Typography
                      variant="body2"
                      component="div"
                      mr={1}
                    >
                      {titleSpecializationName}
                    </Typography>
                    {doctor?.degree && (
                      <Typography variant="subtitle2" component="div">
                        {doctor?.degree}
                      </Typography>
                    )}
                  </Box>
                  <ButtonRating />
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <>
                <Grid item xs={12} sm={6}>
                  <SelectVisitKind
                    itemId={itemId}
                    visitKind={visitKind}
                    visitKinds={visitKinds}
                    handleVisitKindChange={handleVisitKindChange}
                    lang={language}
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12} sm={10}>
              <Typography
                variant="body2"
                component="p"
                style={{ marginTop: 2 }}
              >
                {clinic.name}
              </Typography>
              <Typography variant="subtitle2" component="p">
                <LocationOnIcon className={classes.locationOnIcon} />
                {clinic.address}
              </Typography>
            </Grid>
            {!isMobile && (
              <Grid item xs={12} sm={2} textAlign="right">
                {appInfo.showVisitPrice &&
                  visitKind?.price != null && (
                    <Typography variant="body2">
                      {t('VISIT.FROM')}{' '}
                      {currencyFormat(visitKind.price)}
                    </Typography>
                  )}
              </Grid>
            )}
            <Grid item xs={12} data-testid="doctor-slots">
              {isMobile ? (
                <SelectVisitKind
                  itemId={itemId}
                  visitKind={visitKind}
                  visitKinds={visitKinds}
                  handleVisitKindChange={handleVisitKindChange}
                  lang={language}
                />
              ) : !isEmpty(visitKind) && !isEmpty(timeSlots) ? (
                <Typography
                  variant="body2"
                  component="p"
                  style={{
                    marginTop: 2,
                    marginBottom: 8,
                  }}
                >
                  {visitKind?.name}
                </Typography>
              ) : null}

              <DoctorSlots
                timeSlots={timeSlots}
                timeSlotsIsLoaded={timeSlotsIsLoaded}
                visitKind={visitKind}
                visitDate={visitDate}
                toggleModalCreateVisit={toggleModalCreateVisit}
                doctor={doctor}
                clinic={clinic}
                setModaleInfo={setModaleInfo}
                lang={language}
                isOnline={isOnline}
              />
            </Grid>
            {isMobile && (
              <Grid item xs={12} textAlign="right">
                {appInfo.showVisitPrice &&
                  visitKind?.price != null && (
                    <Typography variant="body2">
                      {t('VISIT.FROM')}{' '}
                      {currencyFormat(visitKind.price)}
                    </Typography>
                  )}
              </Grid>
            )}
          </Grid>
        </Card>
      </>
    ),
    [
      itemId,
      visitDate,
      doctor,
      clinic,
      timeSlotsIsLoaded,
      timeSlots,
      selectedVisitKindId,
      defaultVisitKind,
      visitKinds,
      isOnline,
      review,
      cachedTimeSlots,
      language,
    ],
  );
}
