import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import {
  Collapse as Fade,
  Grid,
  TextField,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

//form func
import useForm from '../../hooks/useForm';
//import validate from './validationDoctorRatingSet';
import { addDoctorReview } from '../../actions/visit';
import { useVisitState } from '../../context/VisitContext';
import Loading from '../../components/Loading';
import isEmpty from '../../helpers';
import { Rating } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  submit: {
    marginTop: theme.spacing(8),
    textAlign: 'center',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',
    minWidth: 200,
  },

  response: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function DoctorRatingSet({ setModalRaring }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    state: {
      doctorRating: { doctor, review, response, isLoaded },
    },
    setState,
  } = useVisitState();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const sendDocReview = () => {
    setState({
      type: 'RESET_RATING_LOADING',
    });

    addDoctorReview(
      setState,
      doctor.clinicId,
      doctor.id,
      null,
      values.rating,
      values.text,
    );
  };

  const { values, handleChange, handleSubmit, setValues } =
    useForm(sendDocReview);

  useEffect(() => {
    setValues({
      rating: review?.rating ?? null,
      text: review?.text ?? '',
    });
  }, []);

  useEffect(() => {
    if (response != null && response === 'OK') {
      setModalRaring(false);
    }
  }, [response]);

  const handleChangeRating = (e) => {
    setValues({
      ...values,
      rating: parseInt(e.target.value, 10),
    });
  };

  return (
    <>
      {!isMobile && (
        <Typography variant="h2" mb={3}>
          {t('VISIT.REVIEW_SET')}
        </Typography>
      )}
      {!isEmpty(values) && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography component="legend">
              {t('VISIT.REVIEW_RATING')}
            </Typography>
            <Rating
              name="rating"
              value={values.rating}
              onChange={handleChangeRating}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              //id="outlined-multiline-static"
              name="text"
              value={values?.text != null ? values?.text : ''}
              onChange={handleChange}
              label={t('VISIT.REVIEW_TEXT')}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} className={classes.submit}>
            <Fade
              in={response !== ''}
              style={
                response === ''
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <Typography
                color={response === 'OK' ? 'success' : 'warning'}
                className={classes.response}
              >
                {response === 'OK'
                  ? t('VISIT.REVIEW_SENDED')
                  : response}
              </Typography>
            </Fade>
            {!isLoaded ? (
              <Loading />
            ) : (
              <Button
                onClick={handleSubmit}
                disabled={values.rating == null && values.text == ''}
                variant="contained"
                size="large"
                color="primary"
                className={classes.submitButton}
              >
                {t('VISIT.REVIEW_SUBMIT')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
