import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Menu, MenuItem, IconButton } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useUserStateDispatch } from '../../context/UserContext';

import { signOut } from '../../actions/user';
import { Typography, Avatar } from '@mui/material';
//import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
//import UsersNonifications from './UsersNonificationsDoctor';

const useStyles = makeStyles((theme) => ({
  headerMenu: {
    marginTop: theme.spacing(4),
  },
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  headerMenuButton: {
    //padding: theme.spacing(0.5)
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  avatarProfile: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  headerFio: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
    textAlign: 'left',
  },
  headerSpec: {
    color: '#B2B6C2',
  },

  headerFioAnanim: {
    color: 'rgba(255, 255, 255, 0.9);',
  },

  profileMenu: {
    minWidth: 265,
  },
  profileMenuUser: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  profileMenuItem: {
    color: theme.palette.primary.main,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
}));

export default function UsersMenu() {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const [profileMenu, setProfileMenu] = useState(null);

  const {
    userState: {
      user: { lastName, firstName, middleName },
    },
    userDispatch,
  } = useUserStateDispatch();

  const fio = `${lastName} ${firstName} ${middleName ?? ''}`;

  return (
    <Grid
      container
      style={{
        alignSelf: 'center',
        marginLeft: 'auto',
        width: 'auto',
      }}
    >
      {/* <Grid item style={{ alignSelf: 'center' }}>
        <UsersNonifications />
      </Grid> */}
      <Grid item style={{ alignSelf: 'center' }}>
        <Typography
          variant="h6"
          size="sm"
          className={classes.headerFio}
        >
          {fio}
        </Typography>
        {/* <Typography
          variant="body2"
          size="sm"
          className={classNames(
            classes.headerFio,
            classes.headerSpec,
          )}
        >
          врач
        </Typography> */}
      </Grid>
      <Grid item>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          className={classes.headerMenuButton}
          aria-controls="profile-menu"
          onClick={(e) => setProfileMenu(e.currentTarget)}
        >
          <Avatar
            alt={fio}
            classes={{ root: classes.avatarProfile }}
          />
        </IconButton>

        <Menu
          id="profile-menu"
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          {/* <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() => {
              setProfileMenu(null);
              navigate('/app/profile');
            }}
          >
            <PersonOutlineIcon className={classes.profileMenuIcon} />
            {t('MAINMENU.PROFILE')}
          </MenuItem> */}

          <MenuItem
            className={classNames(
              classes.profileMenuItem,
              classes.headerMenuItem,
            )}
            onClick={() =>
              signOut(userDispatch, navigate, '/docsign')
            }
          >
            <ExitToAppIcon className={classes.profileMenuIcon} />
            {t('MAINMENU.SIGNOUT')}
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  );
}
