import React, { useMemo, useState } from 'react';
import {
  Alert,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField as Input,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import isEmpty from '../../helpers';
import ButtonEsiaLink from '../../pages/sign_in_up/ButtonEsiaLink';
import { isValidPhone } from '../../helpers/validators';
import { useUserStateDispatch } from '../../context/UserContext';

const useStyles = makeStyles((theme) => ({
  submitButton: {
    height: 54,
    textTransform: 'none',
    width: '100%',
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },

  loginLoader: {
    marginLeft: theme.spacing(4),
  },

  passwordError: {
    color: theme.palette.warning.error,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function SignInForm({
  values,
  errors,
  setValues,
  setErrors,
  validate,

  handleChange,
  handleSubmit,

  valsTab,
  setValsTab,
  appInfo,
  handlePhoneChange,
  showsignUpButton = true,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: { isLoaded, serverResponse },
    userDispatch,
  } = useUserStateDispatch();

  const loginLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('COMPONENT.FORM_EMAIL');
      }
      case 'phone': {
        return t('COMPONENT.FORM_PHONE');
      }
      default: {
        return t('COMPONENT.FORM_EMAIL_OR_PHONE');
      }
    }
  };
  const buttonLabel = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_BUTTON_PHONE');
      }
      case 'phone': {
        return t('SIGN.IN_BUTTON_EMAIL');
      }
    }
  };
  const title = () => {
    switch (valsTab.authIdentifiers) {
      case 'email': {
        return t('SIGN.IN_TITLE_EMAIL');
      }
      case 'phone': {
        return t('SIGN.IN_TITLE_PHONE');
      }
      default: {
        return t('SIGN.IN_TITLE');
      }
    }
  };
  const [visibilePass, setVisibilePass] = useState(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAuthIdentifiers = () => {
    const authIdentifiers =
      valsTab.authIdentifiers === 'email' ? 'phone' : 'email';
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      authIdentifiers,
    });
    const vals = {
      ...values,
      login: null,
    };
    setValues(vals);
    setErrors(validate(vals, appInfo));
  };
  return (
    <>
      {!isLoaded ? (
        <CircularProgress size={26} className={classes.loginLoader} />
      ) : (
        <Grid container spacing={1} mt={1} mb={3}>
          <Grid item xs={7}>
            <Typography variant="h3">{title()}</Typography>
          </Grid>

          <Grid item xs={5} textAlign="right">
            {appInfo.authIdentifiers !== 'both' && (
              <Button color="primary" onClick={handleAuthIdentifiers}>
                {buttonLabel()}
              </Button>
            )}
          </Grid>

          <Grid item xs={12} mt={3}>
            <Fade
              in={!!serverResponse}
              style={
                !serverResponse
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <Alert
                severity="error"
                className={classes.errorMessage}
              >
                <Typography variant="h6">
                  {serverResponse || t('SIGN.IN_ERRORS')}
                </Typography>
              </Alert>
            </Fade>
          </Grid>
          <Grid item xs={12}>
            <Input
              name="login"
              data-testid="login"
              variant="outlined"
              value={values.login || ''}
              onBlur={(e) =>
                valsTab.authIdentifiers !== 'phone' &&
                isValidPhone(e.target.value, appInfo.countryCode) &&
                handlePhoneChange(e)
              }
              onChange={(e) =>
                valsTab.authIdentifiers === 'phone'
                  ? handlePhoneChange(e)
                  : handleChange(e)
              }
              label={loginLabel()}
              type="text"
              fullWidth
              required
              error={values.login != null && errors?.login != null}
              helperText={
                values.login != null &&
                errors?.login != null &&
                errors?.login
              }
              inputProps={{
                'data-testid': 'login',
                autocomplete: 'off',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel htmlFor="outlined-adornment-password">
                {t('COMPONENT.FORM_PASS')}
              </InputLabel>
              <OutlinedInput
                name="password"
                variant="outlined"
                value={values.password || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_PASS')}
                type={visibilePass ? 'text' : 'password'}
                required
                error={errors?.password != null}
                onKeyDown={(e) => {
                  if (
                    errors?.password == null &&
                    values.password !== '' &&
                    e.key === 'Enter'
                  ) {
                    handleSubmit();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisibilePass(!visibilePass)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!visibilePass ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                inputProps={{
                  'data-testid': 'password',
                  autocomplete: 'off',
                }}
              />
              <FormHelperText className={classes.passwordError}>
                {errors?.password != null && errors?.password}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={12} mt={2}>
            <Button
              disabled={
                values.login == null ||
                values.password == null ||
                !isEmpty(errors)
              }
              onClick={handleSubmit}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
              variant="contained"
              color="primary"
              className={classes.submitButton}
              data-testid="but-submit"
            >
              {t('SIGN.IN_TITLE')}
            </Button>
          </Grid>

          {appInfo.showEsiaAuth && (
            <Grid item xs={12} mt={2}>
              <ButtonEsiaLink />
            </Grid>
          )}

          {showsignUpButton && (
            <Grid item xs={12} mt={2}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                  setValsTab({ ...valsTab, activeTabId: 1 })
                }
                style={{ width: '100%' }}
              >
                {t('SIGN.UP_BUTTON')}
              </Button>
            </Grid>
          )}

          <Grid item xs={12} sm={6} mt={2}>
            <Button
              color="primary"
              component="a"
              onClick={() => {
                userDispatch({
                  type: 'LOADED',
                });
                setValsTab({ ...valsTab, activeTabId: 2 });
              }}
              sx={{
                padding: 0,
              }}
            >
              {t('SIGN.CH_PASS_BUTTON')}
            </Button>
          </Grid>

          {appInfo.showQrCodeAuth && (
            <Grid
              item
              xs={12}
              sm={6}
              mt={2}
              sx={{ textAlign: { xs: 'left', sm: 'right' } }}
            >
              <Button
                color="primary"
                component="a"
                onClick={() => {
                  userDispatch({
                    type: 'LOADED',
                  });
                  setValsTab({ ...valsTab, activeTabId: 3 });
                }}
                sx={{
                  padding: 0,
                }}
              >
                {t('SIGN.IN_BY_QR')}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}
