import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import { Box, Button, Switch } from '@mui/material';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Checkbox, IconButton } from '@mui/material';

import Specs from '../../components/FormComponents/AutocompleteSpec';
import SearchForm from '../../components/FormComponents/SearchForm';
import ClinicsFilter from '../../components/FormComponents/AutocompleteClinics';

import { useTranslation } from 'react-i18next';
import { useVisitState } from '../../context/VisitContext';

import useStyles from './styles';
import FilterIcon from '@mui/icons-material/FilterListOutlined';

import Modale from '../../components/Modals/Modale';

import config from '../../config';

import MuiUIPicker from '../../components/MUIDatePicker';
import dayjs from 'dayjs';

import { useUserStateDispatch } from '../../context/UserContext';
import useDoctorsParams from './useDoctorsParams';
import useIsMobile from '../../hooks/useIsMobile';
/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const Filters = ({
  isOnlineParam,
  clinicId,
  fioParam,
  favPram,
  specIdParam,
  isMobile,
  toggleMolale,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    userState: {
      isAuthenticated,
      user: { isAnonymous, lang },
      appInfo,
    },
  } = useUserStateDispatch();
  const {
    state: {
      selectedDate,
      sortOrder,
      cachedTimeSlots,
      filterWithTimeSlotsOnly,
      doctors: { isLoaded },
    },
    setState,
  } = useVisitState();

  const handleDateChange = useCallback(
    (val) => {
      //console.log('handleDateChange', val);
      const newDate = new Date(val);
      if (dayjs(newDate).isValid()) {
        let dateNow = new Date();
        dateNow = new Date(
          Date.UTC(
            dateNow.getFullYear(),
            dateNow.getMonth(),
            dateNow.getDate(),
          ),
        );

        if (newDate >= dateNow)
          setState({
            type: 'SET_SELECTED_DATE',
            payload: { selectedDate: dayjs(newDate) },
          });
      }
    },
    [selectedDate],
  );

  const handleSlotsOnly = () => {
    setState({
      type: 'SET_WITH_SLOTS_ONLY',
      payload: { filterWithTimeSlotsOnly: !filterWithTimeSlotsOnly },
    });
  };

  const handleSelectedSpecChange = (value) => {
    navigate(
      `${config.accessLink(isAuthenticated, 'visit')}/${
        value ?? null
      }/${clinicId}/${isOnlineParam}/${fioParam}/${favPram}`,
    );
  };

  const handleClinicChange = (value) => {
    navigate(
      `${config.accessLink(
        isAuthenticated,
        'visit',
      )}/${specIdParam}/${value}/${isOnlineParam}/${fioParam}/${favPram}`,
    );
  };

  const handleIsOnline = (e, value) => {
    navigate(
      `${config.accessLink(
        isAuthenticated,
        'visit',
      )}/${specIdParam}/${clinicId}/${value}/${fioParam}/${favPram}`,
    );
  };

  const handleChangeFio = (value) => {
    value = value === '' ? null : value;
    navigate(
      `${config.accessLink(
        isAuthenticated,
        'visit',
      )}/${specIdParam}/${clinicId}/${isOnlineParam}/${value}/${favPram}`,
    );
  };

  const handleIsFavs = (e, value) => {
    navigate(
      `${config.accessLink(
        isAuthenticated,
        'visit',
      )}/${specIdParam}/${clinicId}/${isOnlineParam}/${fioParam}/${value}`,
    );
  };
  const handleOrder = (e, value) => {
    setState({
      type: 'SET_ORDER',
      payload: value,
    });
  };

  const handleCache = (e, value) => {
    setState({
      type: 'SET_CACHED',
      payload: value,
    });
  };

  return useMemo(() => {
    return (
      <Box className={classes.filters}>
        {appInfo.showFilterOnline &&
          isAuthenticated &&
          !isAnonymous && (
            <ToggleButtonGroup
              value={isOnlineParam}
              exclusive
              onChange={handleIsOnline}
              aria-label="filter-buttonGroup"
              className={classes.buttonGroupCastom}
            >
              <ToggleButton
                value={false}
                aria-label="offLine"
                disabled={!isLoaded}
                data-testid="filter-off-line"
              >
                <Typography>{t('VISIT.INCLINIC')}</Typography>
              </ToggleButton>
              <ToggleButton
                value={true}
                aria-label="onLine"
                disabled={!isLoaded}
                data-testid="filter-on-line"
              >
                <Typography>{t('VISIT.ONLINE')}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          )}
        <Specs
          handleSelectedSpecChange={handleSelectedSpecChange}
          specIdParam={specIdParam}
          isOnline={isOnlineParam}
          disabled={!isLoaded}
        />
        {isMobile && (
          <SearchForm
            handleChange={handleChangeFio}
            value={fioParam}
            disabled={!isLoaded}
            label={t('COMPONENT.SEARCH_FIO')}
          />
        )}

        <MuiUIPicker
          value={selectedDate}
          handleChange={handleDateChange}
          disablePast={true}
          disabled={!isLoaded}
          label={t('COMPONENT.APPOINTMENT_DATE')}
          preventKeyDown={true}
          maxDate={dayjs().add(appInfo.timeSlotsCacheDepth, 'day')}
        />

        <ClinicsFilter
          handleClinicChange={handleClinicChange}
          selectedClinicId={clinicId}
          disabled={!isLoaded}
        />

        {!isOnlineParam && (
          <>
            <p style={{ margin: 8 }}></p>
            <FormControlLabel
              control={
                <Checkbox
                  name="filter-with-time-slots-only"
                  checked={filterWithTimeSlotsOnly}
                  onChange={handleSlotsOnly}
                  color="primary"
                  disabled={!isLoaded}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                />
              }
              label={
                <Typography variant="h6">
                  {t('VISIT.WITH_SLOTS_ONLY')}
                </Typography>
              }
            />
            <FormControlLabel
              control={
                <Switch
                  onChange={handleOrder}
                  checked={sortOrder}
                  color="primary"
                  disabled={!isLoaded}
                  size="small"
                />
              }
              label={
                <Typography variant="h6" ml={0.8}>
                  {t('VISIT.ORDER')}
                </Typography>
              }
            />
          </>
        )}
        {appInfo.showTimeSlotsCacheSwitch && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleCache}
                checked={cachedTimeSlots}
                color="primary"
                disabled={!isLoaded}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              />
            }
            label={
              <Typography variant="h6">
                {t('VISIT.CACHED')}
              </Typography>
            }
          />
        )}

        {appInfo.showFavoriteDoctors && isAuthenticated && (
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleIsFavs}
                checked={favPram}
                color="primary"
                disabled={!isLoaded}
                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
              />
            }
            label={
              <Typography variant="h6">{t('VISIT.FAVS')}</Typography>
            }
          />
        )}
        {isMobile && (
          <Box align="center" mt={3} mb={2}>
            <Button
              onClick={toggleMolale}
              variant="contained"
              color="primary"
              className={classes.button}
            >
              {t('COMPONENT.BUT_CLOSE')}
            </Button>
          </Box>
        )}
      </Box>
    );
  }, [
    isOnlineParam,
    clinicId,
    fioParam,
    favPram,
    specIdParam,
    isMobile,
    isLoaded,
    lang,
  ]);
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorsFilter() {
  const isMobile = useIsMobile('lg', 768);
  const { t } = useTranslation();

  const { specIdParam, clinicId, isOnlineParam, fioParam, favPram } =
    useDoctorsParams();
  const [modale, setModale] = useState(false);
  const toggleMolale = () => {
    setModale(!modale);
  };

  return !isMobile ? (
    <Filters
      isOnlineParam={isOnlineParam}
      clinicId={clinicId}
      fioParam={fioParam}
      favPram={favPram}
      specIdParam={specIdParam}
      isMobile={isMobile}
      toggleMolale={toggleMolale}
    />
  ) : (
    <>
      <Box
        style={{
          position: 'relative',
          zIndex: 100,
          width: '100%',
          display: 'flex',
          justifyContent: 'end',
          top: -63,
          left: 15,
        }}
      >
        <Box
          style={{
            position: 'fixed',
          }}
        >
          <IconButton
            title={t('COMPONENT.BUT_FILTERS')}
            color="primary"
            onClick={toggleMolale}
            size="large"
          >
            <FilterIcon />
          </IconButton>
        </Box>
      </Box>

      <Modale
        open={modale}
        toggleModal={toggleMolale}
        title={t('COMPONENT.BUT_FILTERS')}
        height={680}
      >
        <Filters
          isOnlineParam={isOnlineParam}
          clinicId={clinicId}
          fioParam={fioParam}
          favPram={favPram}
          specIdParam={specIdParam}
          isMobile={isMobile}
          toggleMolale={toggleMolale}
        />
      </Modale>
    </>
  );
}
