import React, { useState } from 'react';
import SignIn from './SignIn';

import SignUp from './SignUp';
import SignInConfirm from '../../pages/sign_in_up/SignInConfirm';
import ChangePassword from '../../pages/sign_in_up/ChangePassword';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import config from '../../config';
import { useUserStateDispatch } from '../../context/UserContext';
import SignInQr from '../../pages/sign_in_up/SignInQr';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    [theme.breakpoints.up('sm')]: {
      margin: `0 ${theme.spacing(4)}`,
      minWidth: theme.spacing(61),
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign({ toggleModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  // global

  const {
    userState: { appInfo },
    userDispatch,
  } = useUserStateDispatch();

  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    ...config.defLoginPassword,
    authIdentifiers: appInfo.authIdentifiers,
  });

  const _appInfo = {
    ...appInfo,
    authIdentifiers: valsTab.authIdentifiers,
  };
  const setActiveTabId = (activeTabId) => {
    userDispatch({ type: 'LOADED' });
    setValsTab({
      ...valsTab,
      activeTabId,
    });
  };
  return (
    <Box className={classes.conteiner}>
      {valsTab.activeTabId === 0 ? (
        valsTab?.warningNote === 'CONFIRM_AUTH' ? (
          <>
            <Typography
              variant="h4"
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              {t('SIGN.CH_CONFIRM_CODE')}
            </Typography>
            <SignInConfirm
              valsTab={valsTab}
              setValsTab={setValsTab}
            />
          </>
        ) : (
          <SignIn
            appInfo={_appInfo}
            valsTab={valsTab}
            setValsTab={setValsTab}
            toggleModal={toggleModal}
            setActiveTabId={setActiveTabId}
          />
        )
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          valsTab={valsTab}
          setValsTab={setValsTab}
          toggleModal={toggleModal}
          setActiveTabId={setActiveTabId}
        />
      ) : valsTab.activeTabId === 2 ? (
        <ChangePassword setValsTab={setValsTab} valsTab={valsTab} />
      ) : valsTab.activeTabId === 3 ? (
        <SignInQr setActiveTabId={setActiveTabId} />
      ) : null}
    </Box>
  );
}

export default Sign;
