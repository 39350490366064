import React from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FeedBackForm from '../../components/Forms/FeedBackForm';
import LeftMenu from '../../components/Sidebar/LeftMenu';
import { useUserStateDispatch } from '../../context/UserContext';

export default function FeedBackPage() {
  const { t } = useTranslation();
  const {
    userState: { structure },
  } = useUserStateDispatch();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12} md={4} lg={3}>
        <LeftMenu isProfile={true} />
      </Grid>
      <Grid item xs={12} sm={12} md={8} lg={9}>
        <Typography variant="h1" mb={5}>
          {t('FEEDBACK.FORM_TITLE')}
        </Typography>
        <Typography variant="h6" mb={3}>
          {t('FEEDBACK.FORM_SUBTITLE')}
        </Typography>
        <FeedBackForm />
      </Grid>
    </Grid>
  );
}
