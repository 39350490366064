import React, { useState } from 'react';
import {
  Grid,
  Fade,
  Alert,
  Link as LinkBase,
  Box,
} from '@mui/material';
import { Link } from 'react-router-dom';

// styles
import useStyles from './styles';

import heroImg from '../../images/heroImgDoc.svg';
import logo from '../../images/logo_hero_mobimed.svg';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SignIn from './SignInForm';
import SignInConfirm from './SignInConfirm';
import SignInMedDepId from './SignInMedDepId';
// import SignUp from './SignUp';
// import ChangePassword from './ChangePassword';

import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

//import UsersLanguageButtons from '../../components/Header/UsersLanguageButtons';
import LoginIcon from '@mui/icons-material/Login';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import config from '../../config';
import classNames from 'classnames';
import logoRuStore from '../../images/ruStoreSmall.svg';
import logoAppStore from '../../images/appStoreSmall.svg';
import googlePlay from '../../images/googlePlaySmall.svg';
import custom from '../../custom';
import { getYearNow } from '../../helpers';

function Sign() {
  const classes = useStyles();
  const { t } = useTranslation();

  const { userState } = useUserStateDispatch();
  const {
    serverResponse,
    doctor: { step },
    appInfo,
  } = userState;

  const [valsTab, setValsTab] = useState({
    activeTabId: 0,
    //    email: '',
    //   password: '',
    email: config.defLoginPassword.login_doc,
    password: config.defLoginPassword.password_doc,
  });
  const inHead = custom.defSignHeder ?? t('SIGN.IN_HEAD');

  return (
    <Grid container className={classes.container}>
      <div className={classes.topbg}></div>
      <div className={classes.topbghr}></div>
      <div className={classes.top}>
        <div className={classes.topContainer}>
          <div>
            <LinkBase href="/">
              <img
                src={logo}
                alt={t('SIGN.IN_HEAD_DOC')}
                className={classes.logotypeImage}
              />
            </LinkBase>
          </div>

          {appInfo.showStoreLinks && (
            <>
              <Box ml={8} />
              {appInfo.linkAppStore !== '' && (
                <LinkBase href="#" target="_blank">
                  <img src={logoAppStore} alt={inHead} />
                </LinkBase>
              )}
              {appInfo.linkAppStore !== '' &&
                (appInfo.linkGooglePlay !== '' ||
                  appInfo.linkRuStore !== '') && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkGooglePlay !== '' && (
                <LinkBase
                  href={appInfo.linkGooglePlay}
                  target="_blank"
                >
                  <img src={googlePlay} alt={inHead} />
                </LinkBase>
              )}

              {(appInfo.linkAppStore !== '' ||
                appInfo.linkGooglePlay !== '') &&
                appInfo.linkRuStore !== '' && (
                  <div className={classes.divide}></div>
                )}

              {appInfo.linkRuStore !== '' && (
                <LinkBase href={appInfo.linkRuStore} target="_blank">
                  <img src={logoRuStore} alt={inHead} />
                </LinkBase>
              )}
            </>
          )}
          <div
            className={classNames(classes.signBtn, classes.toRight)}
          >
            <Link to="/" className={classes.link}>
              <Button
                variant="contained"
                color="secondary"
                aria-label="in doctor"
                startIcon={<LoginIcon />}
              >
                <Typography
                  variant="body1"
                  style={{ marginLeft: 4, color: '#000' }}
                >
                  {t('SIGN.IN_PATIENT_ENTER')}
                </Typography>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <div className={classes.leftContainer}>
        <div className={classes.formContainer}>
          <Typography variant="h1" className={classes.greeting}>
            {t('SIGN.IN_HEAD_DOC')}
          </Typography>
          <Typography variant="h5" className={classes.greeting1}>
            {t('SIGN.IN_HEAD_DOC1')}
          </Typography>

          <div className={classes.form}>
            {/* <Tabs
              value={valsTab.activeTabId}
              onChange={(e, id) =>
                setValsTab({ ...valsTab, activeTabId: id })
              }
              indicatorColor="primary"
              textColor="primary"
              style={{ marginBottom: 24 }}
            >
              <Tab
                label={t('SIGN.IN_BUTTON')}
                classes={{ root: classes.tab }}
              />
              <Tab
                label={t('SIGN.UP_BUTTON')}
                classes={{ root: classes.tab }}
              />
              {valsTab.activeTabId === 2 && (
                <Tab
                  label={t('SIGN.CH_PASS_BUTTON')}
                  classes={{ root: classes.tab }}
                />
              )}
            </Tabs> */}
            <Fade
              in={!!serverResponse}
              style={
                serverResponse === ''
                  ? { display: 'none' }
                  : { display: 'flex' }
              }
            >
              <Alert
                severity="error"
                className={classes.errorMessage}
              >
                <Typography variant="h6">
                  {serverResponse === 'OK'
                    ? t('SIGN.UP_OK')
                    : serverResponse === 'WRONG_LOGIN_OR_PASSWORD'
                    ? t('SIGN.UP_WRONG_LOG_OR_PASS')
                    : serverResponse === 'EMAIL_ALREADY_REGISTERED'
                    ? t('SIGN.UP_EMAIL_ALREADY_REG')
                    : serverResponse === 'MAY_LOGIN'
                    ? t('SIGN.UP_MAY_LOGIN')
                    : serverResponse === 'WRONG_CONFIRMATION_CODE'
                    ? t('SIGN.UP_CONFIRM_CODE_WRONG')
                    : serverResponse}
                </Typography>
              </Alert>
            </Fade>
            {valsTab.activeTabId === 0 &&
              (step === 0 || step == null ? (
                <SignIn valsTab={valsTab} setValsTab={setValsTab} />
              ) : step === 1 ? (
                <SignInMedDepId setValsTab={setValsTab} />
              ) : step === 2 ? (
                <SignInConfirm setValsTab={setValsTab} />
              ) : null)}
          </div>
        </div>
      </div>
      <div className={classes.heroImgContainer}>
        <img
          src={heroImg}
          alt={t('SIGN.IN_HEAD_DOC')}
          className={classes.heroImage}
        />
      </div>
      <div className={classes.bottom}>
        <div className={classes.bottomContainer}>
          <Divider />
          <Typography className={classes.copyright}>
            {t('BOTTOM.COPY', { year: getYearNow() })}{' '}
            <Link to="/app/hospital-form" className={classes.link}>
              {appInfo.defTitle}
            </Link>
          </Typography>
        </div>
      </div>
    </Grid>
  );
}

export default Sign;
