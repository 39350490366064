import i18next from 'i18next';
import { EnpValidator } from '@pmt/validation';

import {
  isValidPhone,
  notAvalibleBirthDate,
  rexFio,
  isPastDate,
} from '../../../helpers/validators';

export const MIN_BIRTH_YEARs = -120;
export const MAX_BIRTH_YEARs = -1;

export function validate(values, appInfo) {
  let errors = {};
  console.log('\n\n=== values ===\n\n', values);
  console.log('\n\n=== errors ===\n\n', errors);
  if (values.birthDate != null && !values.birthDate) {
    errors.birthDate = i18next.t('COMPONENT.EMPTY_BIRTHDAY');
  } else if (
    values.birthDate != null &&
    (!values.birthDate.isValid() ||
      notAvalibleBirthDate(values.birthDate, [
        MIN_BIRTH_YEARs,
        MAX_BIRTH_YEARs,
      ]))
  ) {
    errors.birthDate = i18next.t('COMPONENT.INVALID_BIRTH_DATE');
  }

  if (values.callDate != null && !values.callDate.isValid()) {
    errors.callDate = i18next.t('CALL_DOCTOR.INVALID_CALL_DATE');
  }
  if (values.callDate != null && isPastDate(values.callDate)) {
    errors.callDate = i18next.t('CALL_DOCTOR.INVALID_CALL_DATE');
  }

  /**/

  if (values.city != null && !values.city) {
    errors.city = i18next.t('CALL_DOCTOR.EMPTY_CITY');
  }
  if (values.street != null && !values.street) {
    errors.street = i18next.t('CALL_DOCTOR.EMPTY_STREET');
  }
  if (values.house != null && !values.house) {
    errors.house = i18next.t('CALL_DOCTOR.EMPTY_HOUSE');
  }
  if (values.flat != null && !values.flat) {
    errors.flat = i18next.t('CALL_DOCTOR.EMPTY_FLAT');
  }
  if (values.entrance != null && !values.entrance) {
    errors.entrance = i18next.t('CALL_DOCTOR.EMPTY_ENTRANCE');
  }
  if (values.floor != null && !values.floor) {
    errors.floor = i18next.t('CALL_DOCTOR.EMPTY_FLOOR');
  }
  if (values.intercom != null && !values.intercom) {
    errors.intercom = i18next.t('CALL_DOCTOR.EMPTY_DOORPHONE');
  }

  if (values.firstName != null && !values.firstName) {
    errors.firstName = i18next.t('COMPONENT.EMPTY_NAME');
  }
  if (values.lastName != null && !values.lastName) {
    errors.lastName = i18next.t('COMPONENT.EMPTY_LAST_NAME');
  }

  if (values.firstName != null && !rexFio.test(values.firstName)) {
    errors.firstName = i18next.t('SIGN.UP_NOT_VALID_NAME');
  }
  if (values.lastName != null && !rexFio.test(values.lastName)) {
    errors.lastName = i18next.t('SIGN.UP_NOT_VALID_LAST_NAME');
  }
  if (values.middleName && !rexFio.test(values.middleName)) {
    errors.middleName = i18next.t('SIGN.UP_NOT_VALID_MIDDLE_NAME');
  }

  if (!values.reason) {
    errors.reason = i18next.t('CALL_DOCTOR.EMPTY_REASON');
  }
  if (values.ageGroup == null) {
    errors.ageGroup = i18next.t('COMPONENT.NEED_AGE_GR');
  }
  if (!values.hasCovid) {
    errors.hasCovid = i18next.t('CALL_DOCTOR.NEED_HAS_COVID');
  }

  if (
    values.phoneNumber != null &&
    !isValidPhone(values.phoneNumber, appInfo.countryCode)
  ) {
    errors.phoneNumber = i18next.t('COMPONENT.INCORECT_PHONE');
  }

  if (values.dmsName && !values.dmsName) {
    errors.dmsName = i18next.t('COMPONENT.NEED_DMS_NAME');
  }
  if (values.dmsEndDate != null && !values.dmsEndDate.isValid()) {
    errors.dmsEndDate = i18next.t('CALL_DOCTOR.INVALID_DMS_END_DATE');
  }
  if (values.dmsEndDate != null && isPastDate(values.dmsEndDate)) {
    errors.dmsEndDate = i18next.t('CALL_DOCTOR.INVALID_DMS_END_DATE');
  }
  if (values.dmsName && !values.dmsNumber) {
    errors.dmsNumber = i18next.t('COMPONENT.NEED_DMS_NUMBER');
  }
  if (values.omsName && !values.omsNumber) {
    errors.omsNumber = i18next.t('COMPONENT.NEED_OMS_NUMBER');
  }
  if (values.omsNumber) {
    const validationResult = EnpValidator.validate(values.omsNumber);
    if (!validationResult.valid)
      errors.omsNumber = i18next.t('COMPONENT.INVALID_OMS_NUMBER');
  }

  return errors;
}
