import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import isEmpty, { groupBy } from '../../helpers';

import PrescribedDrugIntakes from './PrescribedDrugIntakes';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(2),
  },

  itemLast: {
    textAlign: 'right',
  },

  locationOnIcon: {
    top: 5,
    position: 'relative',
    color: theme.palette.primary.light,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },

  height100per: { height: '100%' },
  accord: {
    border: 0,
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
    borderRadius: 8,
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
      padding: 0,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 500,
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
  },

  itemContainer: {
    marginBottom: theme.spacing(1),
    position: 'relative',
    borderRadius: 6,
  },

  progressWrapper: {
    width: '100%',
    height: '100%',
    clear: 'both',
    display: 'block',
    position: 'absolute',
    zIndex: -5,
  },

  progressBar: {
    width: '100%',
    height: '100%',
    backgroundColor: '#ebf1ff',
    borderRadius: 6,
  },

  progressBarFill: {
    display: 'block',
    height: '100%',
    backgroundColor: '#d2dffd',
    borderRadius: '6px  0 0 6px ',
  },
  bgItemCurrent: {
    backgroundColor: '#d2dffd',
  },
  bgItemFuture: {
    backgroundColor: '#EBF1FF',
  },
  bgItemPast: {
    backgroundColor: '#EDEDED',
  },
  bgItem: {
    backgroundColor: '#EDEDED',
  },
  bgItemOne: {
    backgroundColor: '#EDEDED',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function PrescribedDrugsItem({
  clinics,
  lang,
  prescribedDrug,
  inx,
  filterPrescribedDrug,
  setState,
}) {
  const { t } = useTranslation();

  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
  };

  const percent = (allDaysCount, leftDaysCount) =>
    leftDaysCount !== 0 ? (leftDaysCount * 100) / allDaysCount : 0;

  const drugIntakesByTime = groupBy(
    prescribedDrug.drugIntakes,
    'dateTime',
    (val) => val.substring(0, 8),
  );

  const isProgressBar =
    percent(
      prescribedDrug.allDaysCount,
      prescribedDrug.leftDaysCount,
    ) !== 0 && expanded !== `panel${inx}`;

  const bgByFilter =
    classes[
      `bgItem${
        filterPrescribedDrug != null ? filterPrescribedDrug : ''
      }`
    ];
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      className={classnames(
        classes.itemContainer,
        expanded === `panel${inx}` || !isProgressBar
          ? bgByFilter
          : null,
      )}
    >
      <Grid
        item
        classes={{
          root: classes.item,
        }}
        xs={12}
        sm={6}
      >
        <Typography variant="h6" style={{ marginBottom: 24 }}>
          {prescribedDrug.drugFullName}
        </Typography>
        <Grid container>
          <Typography component="div" variant="body2">
            {t('DRUGS.LEFT_DAYS')}:{' '}
          </Typography>
          <Typography component="div" variant="body2">
            {prescribedDrug.leftDaysCount} {t('DRUGS.OF')}
            {prescribedDrug.allDaysCount}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        item
        classes={{
          root: classes.item,
        }}
        xs={12}
        sm={3}
      >
        <Typography variant="h6" component="p">
          {
            clinics.find((it) => it.id === prescribedDrug.clinicId)
              ?.name
          }
        </Typography>

        <Typography variant="body2" component="p">
          <LocationOnIcon className={classes.locationOnIcon} />
          {
            clinics.find((it) => it.id === prescribedDrug.clinicId)
              ?.address
          }
        </Typography>
      </Grid>

      <Grid
        item
        classes={{
          root: classnames(classes.item, classes.itemLast),
        }}
        xs={12}
        sm={3}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.height100per}
        >
          <Grid item>
            <Typography variant="h6">
              {prescribedDrug.doctor.lastName}{' '}
              {prescribedDrug.doctor.firstName}{' '}
              {prescribedDrug.doctor.middleName ?? ''}
            </Typography>
            <Typography
              variant="body1"
              className={classes.marginBottom}
            >
              {prescribedDrug.doctor.specialization}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        classes={{
          root: classes.item,
        }}
        xs={12}
        sm={12}
      >
        <Accordion
          defaultExpanded={false}
          classes={
            expanded !== `panel${inx}` && {
              root: classes.accord,
            }
          }
          expanded={expanded === `panel${inx}`}
          onChange={handleChange(inx)}
        >
          <AccordionSummary
            classes={{
              root: classes.Accordion,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="h6">{t('DRUGS.INTAKES')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!isEmpty(prescribedDrug.drugIntakes) && (
              <PrescribedDrugIntakes
                drugIntakesByTime={drugIntakesByTime}
                lang={lang}
                setState={setState}
                clinicId={prescribedDrug.clinicId}
                filterPrescribedDrug={filterPrescribedDrug}
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      {isProgressBar && (
        <div className={classes.progressWrapper}>
          <div className={classes.progressBar}>
            <span
              className={classes.progressBarFill}
              style={{
                width: `${percent(
                  prescribedDrug.allDaysCount,
                  prescribedDrug.leftDaysCount,
                )}%`,
              }}
            ></span>
          </div>
        </div>
      )}
    </Grid>
  );
}
