import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
//import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CircularProgress from '@mui/material/CircularProgress';
import isEmpty from '../../helpers';
import { currencyFormat } from '../../helpers';
import { useInvoicesState } from '../../context/InvoicesContext';
import { getInvoiceDetails } from '../../actions/invoices';

import { useUserStateDispatch } from '../../context/UserContext';
//import Button from "@mui/material/Button";
import { Alert, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';

const useStyles = makeStyles((theme) => ({
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  itemHeight100: {
    height: '100%',
  },

  itemGep: {
    width: 2,
    height: 30,
    backgroundColor: theme.palette.warning.main,
    borderRadius: 1,
  },

  item: {
    padding: theme.spacing(2),
  },
  itemPaid: {
    backgroundColor: theme.palette.bgLight.one,
  },

  itemNotPaid: {
    backgroundColor: '#FEF1F0',
  },

  itemLast: {
    textAlign: 'right',
  },

  LocationOnIcon: {
    top: 5,
    position: 'relative',
    color: theme.palette.primary.light,
  },
  marginBottom: { marginBottom: theme.spacing(1) },
  marginBottomAuto: { marginTop: 'auto' },
  height100per: { height: '100%' },
  Accordion: {
    border: 0,
    backgroundColor: 'inherit',
    boxShadow: 'none',
    padding: 0,
    '&::before': {
      display: 'none',
    },
    '& .MuiAccordionSummary-root': {
      minHeight: 22,
    },
    '& .MuiAccordionSummary-content': {
      margin: 0,
      color: theme.palette.primary.light,
      fontWeight: 500,
    },
    '& .MuiAccordionDetails-root ': {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      display: 'block',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      color: theme.palette.primary.light,
    },
  },
  detailTitle: {
    borderBottom: `1px dashed ${theme.palette.secondary.light}`,
  },
  detailPrice: {
    borderBottom: `1px dashed ${theme.palette.secondary.light}`,
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function InvoicesItem({
  invoice,
  invoiceId,
  paramClinicId,

  openModalPaid,
  paidData,
}) {
  const { t } = useTranslation();
  //console.log("invoice", invoice);

  const {
    userState: {
      clinics,
      user: { mmkId, lang },
      appInfo,
    },
  } = useUserStateDispatch();
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { setState } = useInvoicesState();
  const needPaid = !invoice.isPaid && invoice.canBePaid;

  //console.log('invoice', invoice);

  const handleChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${id}` : false);
    if (!expanded)
      getInvoiceDetails({
        setState,
        mmkId,
        clinicId: invoice.clinicId,
        invoiceId: invoice.id,
        needPaid: false,
      });
  };

  useEffect(() => {
    if (invoiceId != null && paramClinicId != null) {
      handleChange(invoiceId)(null, true);
      setState({
        type: 'SET_FILTER',
        payload: {
          paymentStatus: !invoice.isPaid ? 'Unpaid' : 'Paid',
        },
      });
    }
  }, []);

  //console.log('== invoice==', invoice);
  const clinic = clinics.find((it) => it.id === invoice.clinicId);
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.marginBottom}
    >
      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            classes.borderRadiusLeft,
            invoice.isPaid ? classes.itemPaid : classes.itemNotPaid,
          ),
        }}
        xs={12}
        sm={6}
      >
        <Typography variant="h6">
          {t('INVOICES.INVOICE_NO')}
          {invoice.number}
        </Typography>
        <Typography variant="body2">
          {HL7ToDateToSrtDate(
            invoice.date,
            lang,
            lang === 'ru' ? 'DD.MM.YYYY' : 'MMM DD, YYYY',
          )}
        </Typography>

        <Accordion
          defaultExpanded={false}
          classes={{
            root: classes.Accordion,
          }}
          disabled={
            invoiceId != null &&
            paramClinicId != null &&
            !invoice.invoiceDetails?.isLoaded
          }
          expanded={expanded === `panel${invoice.id}`}
          onChange={handleChange(invoice.id)}
        >
          <AccordionSummary
            classes={{
              root: classes.Accordion,
            }}
            expandIcon={<ExpandMoreIcon />}
          >
            {t('COMPONENT.DATAILS')}
          </AccordionSummary>
          <AccordionDetails>
            {!invoice.invoiceDetails?.isLoaded && (
              <CircularProgress size={26} />
            )}

            {!isEmpty(invoice.invoiceDetails?.data) &&
              invoice.invoiceDetails.data.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <Typography variant="h6">
                      {item.doctor.lastName} {item.doctor.firstName}{' '}
                      {item.doctor.middleName ?? ''}
                    </Typography>
                    <Typography
                      variant="body1"
                      className={classes.marginBottom}
                    >
                      {item.doctor.specialization}
                    </Typography>
                    <Typography variant="body2">
                      {item.name}
                    </Typography>
                    <Grid container className={classes.marginBottom}>
                      <Grid
                        item
                        xs={4}
                        className={classes.detailTitle}
                      >
                        <Typography variant="body2">
                          {t('INVOICES.QUANTITY')}:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        className={classes.detailPrice}
                      >
                        <Typography variant="body2">
                          {item.count}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        className={classes.detailTitle}
                      >
                        <Typography variant="body2">
                          {t('INVOICES.COST')}:
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        className={classes.detailPrice}
                      >
                        <Typography variant="body2">
                          {currencyFormat(item.price)}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!invoice.canBePaid && (
                      <Typography
                        variant="body2"
                        width="medium"
                        component="p"
                      >
                        {invoice.paymentStatusInfo}
                      </Typography>
                    )}
                  </React.Fragment>
                );
              })}

            {invoice.invoiceDetails?.responseDetailError != null && (
              <Alert severity="error">
                <Typography variant="h6" component="p">
                  {invoice.invoiceDetails?.responseDetailError}
                </Typography>
              </Alert>
            )}
          </AccordionDetails>
        </Accordion>
      </Grid>

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            invoice.isPaid ? classes.itemPaid : classes.itemNotPaid,
          ),
        }}
        xs={12}
        sm={3}
      >
        {!isEmpty(clinic) && (
          <>
            <Typography variant="h6" component="p">
              {clinic?.name}
            </Typography>

            <Typography variant="subtitle2" component="p">
              <LocationOnIcon className={classes.LocationOnIcon} />
              {clinic?.address}
            </Typography>
          </>
        )}
      </Grid>

      <Grid className={classes.ifMobileThenHide}>
        <Grid
          container
          className={classes.itemHeight100}
          justifyContent="center"
          alignItems="center"
        >
          <Grid className={classes.itemGep}></Grid>
        </Grid>
      </Grid>

      <Grid
        item
        classes={{
          root: classnames(
            classes.item,
            classes.borderRadiusRight,
            classes.itemLast,
            invoice.isPaid ? classes.itemPaid : classes.itemNotPaid,
          ),
        }}
        xs={12}
        sm
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.height100per}
        >
          <Grid item>
            <Typography variant="h6" width="medium">
              {currencyFormat(invoice.sum)}
            </Typography>
          </Grid>
          {appInfo.isPaymentsEnabled &&
            needPaid &&
            paidData?.isLoaded && (
              <Grid item className={classes.marginBottomAuto}>
                <Button
                  color="warning"
                  variant="contained"
                  onClick={() => {
                    handleChange(invoice.id)(null, true);
                    openModalPaid(invoice);
                  }}
                >
                  {t('INVOICES.TO_PAY')}
                </Button>
              </Grid>
            )}
        </Grid>
      </Grid>
    </Grid>
  );
}
