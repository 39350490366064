import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { HL7ToDateToSrtDate } from '../../helpers/dateFormat';
import { Rating } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  borderRadiusRight: {
    borderRadius: '0 6px 6px 0',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 6px 6px',
    },
  },
  borderRadiusLeft: {
    borderRadius: '6px 0 0 6px',
    [theme.breakpoints.down('sm')]: {
      borderRadius: '6px 6px 0 0',
    },
  },
  ifMobileThenHide: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  item: {
    backgroundColor: theme.palette.bgLight.one,
    padding: theme.spacing(2),
  },
  itemHeight100: {
    height: '100%',
  },

  itemGep: {
    width: 2,
    height: 30,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },

  marginBottom: { marginBottom: theme.spacing(2) },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const RatingReder = ({ rating }) => (
  <Rating
    value={rating ?? null}
    size="small"
    //emptyIcon={<StarBorderIcon fontSize="inherit" />}
    readOnly
  />
);

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

export default function ReviewItem({ item, lang }) {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.marginBottom}
    >
      <Grid
        item
        classes={{
          root: cn(classes.item, classes.borderRadiusLeft),
        }}
        xs={12}
        sm
      >
        <RatingReder rating={item.rating} />
        <Typography variant="body1">{item.text}</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Grid
          container
          classes={{
            root: classes.borderRadiusRight,
          }}
          className={cn(classes.item, classes.itemHeight100)}
          justifyContent="center"
        >
          <Grid item xs>
            <Typography align="right" variant="subtitle2">
              {HL7ToDateToSrtDate(item.date, lang, 'dd MMMM')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
