import React from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';

import Visits from './Visits';
import { useTranslation } from 'react-i18next';
import VisitsFilter from './VisitsFilter';
import { makeStyles } from '@mui/styles';
import VisitModalChatConf from './VisitModalChatConf';
import { useVisitsState } from '../../context/VisitsContext';

import { useUserStateDispatch } from '../../context/UserContext';
import useModalChatVisit from './useModalChatVisit';
const useStyles = makeStyles((theme) => ({
  filtersFixed: {
    position: 'fixed',
    maxWidth: 293,
    zIndex: 100,
  },
  content: {
    marginTop: theme.spacing(6),
  },
}));

export default function VisitsContainer() {
  const { t } = useTranslation();
  const { specificRecordId } = useParams();
  const classes = useStyles();
  const {
    userState: {
      user: { lang },
    },
  } = useUserStateDispatch();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  const HeaderH1 = () => (
    <Typography variant="h1" mb={3}>
      {t('MAINMENU.EVENTS')}
    </Typography>
  );
  const {
    state: { visit },
  } = useVisitsState();

  const { modalChatVisit, toggleModalChatVisit } =
    useModalChatVisit();

  return (
    <>
      <VisitModalChatConf
        chatInfo={visit?.chatInfo}
        lang={lang}
        modalChatVisit={modalChatVisit}
        toggleModalChatVisit={toggleModalChatVisit}
      />
      {!isMobile ? (
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {specificRecordId == null && (
            <Grid item xs={12} sm={12} md={4} lg={3}>
              <div className={classes.filtersFixed}>
                <VisitsFilter />
              </div>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={specificRecordId == null ? 8 : 12}
            lg={specificRecordId == null ? 9 : 12}
          >
            <HeaderH1 />
            <Visits toggleModalChatVisit={toggleModalChatVisit} />
            {specificRecordId != null && (
              <Box my={4} textAlign="center">
                <Button
                  variant="contained"
                  color="secondary"
                  component={Link}
                  to="/app/events_and_visits"
                >
                  {t('INVOICES.FILTER_ALL')}
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <HeaderH1 />
          {specificRecordId == null && <VisitsFilter />}
          <Visits toggleModalChatVisit={toggleModalChatVisit} />
          {specificRecordId != null && (
            <Box my={4} textAlign="center">
              <Button
                variant="outlined"
                color="secondary"
                component={Link}
                to="/app/events_and_visits"
              >
                {t('INVOICES.FILTER_ALL')}
              </Button>
            </Box>
          )}
        </>
      )}
    </>
  );
}
