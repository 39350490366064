import React, { useState, useEffect } from 'react';

// context
import { useUserStateDispatch } from '../../context/UserContext';

import { loginUser } from '../../actions/user';

//form func
import useForm from '../../hooks/useForm';
import validate from './validationSignIn';

import SignInForm from '../../components/Forms/SignInForm';
import { useNavigate } from 'react-router-dom';
import { useLanguageValue } from '../../context/LanguageContext';
import { useMediaQuery } from '@mui/material';

//import EsiaLink from './EsiaLink';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function SignIn({ valsTab, setValsTab }) {
  // global
  const {
    userDispatch,
    userState: { appInfo },
  } = useUserStateDispatch();
  const {
    languageState: { language },
  } = useLanguageValue();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('sm'),
  );

  // local

  const navigate = useNavigate();
  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,
      navigate,
      setValsTab,
      valsTab,
      language,
      appInfo.isAnonymousChildrenEnabled,
    );

  const _appInfo = {
    ...appInfo,
    authIdentifiers: valsTab.authIdentifiers,
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
    handlePhoneChange,
  } = useForm(login, validate, _appInfo);

  useEffect(() => {
    if (valsTab?.login && valsTab?.password) {
      setValues({
        login: valsTab.login,
        password: valsTab.password,
      });
      if (valsTab?.warningNote === 'CHANGED_PASS')
        setTimeout(handleSubmit, 100);
    }
  }, []);

  return (
    <SignInForm
      values={values}
      errors={errors}
      setValues={setValues}
      setErrors={setErrors}
      validate={validate}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      valsTab={valsTab}
      setValsTab={setValsTab}
      appInfo={appInfo}
      handlePhoneChange={handlePhoneChange}
      showsignUpButton={false}
    />
  );
}

export default SignIn;
