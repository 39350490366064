import isEmpty, { ifEmptyArr } from '../helpers';
import httpClient, { getError } from '../api/httpClient';
import api from '../api/apiParams';

export const DEFAULT_PAGE_SIZE = 120;

export async function getInvoices({
  setState,
  mmkId = 'parent',
  clinicId = 0,
  paymentStatus = 'Unpaid',
  pageIndex = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  dateFrom = null,
  maxDate = null,
  local = false,
}) {
  const body = {
    mmkId,
    clinicId,
    paymentStatus,
    dateFrom,
    maxDate,
    pageIndex,
    pageSize,
    local: !local ? undefined : local, // if api unexpected local
  };
  await httpClient
    .get(api.url('GetInvoices'), body)
    .then(async ({ data }) => {
      // console.log(
      //   '--------- request GetInvoices--------- \n',
      //   body,
      //   '--------- response GetInvoices--------- \n',
      //   data,
      // );

      setState({
        type: 'FETCH_INVOICES',
        payload: {
          data: ifEmptyArr(data),
        },
      });
    })
    .catch((err) => {
      // console.log('= axios ERROR GetInvoices =', err);
      setState({
        type: 'FETCH_INVOICES_ERROR',
        payload: getError(err),
      });
    });
}

export async function getInvoiceDetails({
  setState,
  mmkId,
  clinicId,
  invoiceId,
  needPaid,
}) {
  setState({
    type: 'FETCH_INVOICE_DETAILS',
    payload: {
      invoiceId,
      isLoaded: false,
      data: null,
    },
  });

  const bodyDetails = {
    mmkId,
    clinicId,
    invoiceId,
  };
  try {
    const { data } = await httpClient.get(
      api.url('GetInvoiceDetails'),
      bodyDetails,
    );

    //console.log('= axios GetInvoiceDetails ==', data);
    if (needPaid) {
      getInvoicePaidLink({ setState, mmkId, clinicId, invoiceId });
    }
    setState({
      type: 'FETCH_INVOICE_DETAILS',
      payload: {
        isLoaded: true,
        invoiceId,
        data,
        //paidLink: res.data || null,
      },
    });
  } catch (error) {
    //console.log('= axios =\n', getError(error));
    setState({
      type: 'FETCH_INVOICE_DETAILS_ERROR',
      payload: {
        invoiceId,
        isLoaded: true,
        responseDetailError: getError(error),
      },
    });
  }
}

export async function getInvoicePaidLink({
  setState,
  mmkId,
  clinicId,
  invoiceId,
}) {
  const bodyInvoicePayLink = {
    clinicId,
    invoiceId,
    mmkId,
  };
  try {
    setState({
      type: 'FETCH_INVOICE_DETAILS',
      payload: {
        invoiceId,
        isLoaded: false,
        paidLink: null,
        clinicId,
      },
    });

    const { data } = await httpClient.get(
      api.url('GetInvoicePayLink'),
      bodyInvoicePayLink,
    );

    //console.log('= axios getInvoicePaidLink ==', data);

    if (data != null) {
      setState({
        type: 'FETCH_INVOICE_DETAILS',
        payload: {
          invoiceId,
          isLoaded: true,
          paidLink: data,
          clinicId,
        },
      });
    }
  } catch (error) {
    //console.log('= axios =\n', getError(error));
    setState({
      type: 'FETCH_INVOICE_DETAILS_ERROR',
      payload: {
        invoiceId,
        isLoaded: true,
        responseDetailError: getError(error),
      },
    });
  }
}
export async function getInvoiceById({
  setState,
  invoiceId,
  mmkId = 'parent',
  clinicId = 0,
}) {
  const body = {
    mmkId,
    clinicId,
    invoiceId,
  };
  await httpClient
    .get(api.url('GetInvoiceById'), body)
    .then(({ data }) => {
      // console.log(
      //   '--------- request GetInvoiceById--------- \n',
      //   body,
      //   '--------- response GetInvoiceById--------- \n',
      //   data,
      // );

      if (!isEmpty(data))
        setState({
          type: 'FETCH_INVOICE',
          payload: {
            invoiceId,
            data,
          },
        });
      else
        setState({
          type: 'FETCH_INVOICE',
          payload: {
            data: [],
          },
        });
    })
    .catch((err) => {
      //console.log('= axios ERROR GetInvoiceById =', err);
      setState({
        type: 'FETCH_INVOICES_ERROR',
        payload: getError(err),
      });
    });
}
