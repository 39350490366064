import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  CircularProgress,
  Collapse as Fade,
  TextField as Input,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  IconButton,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  InputAdornment,
  Select,
  Alert,
} from '@mui/material';

// icons
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

//components
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modale from '../../components/Modals/Modale';

//form func

import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import rehypeRaw from 'rehype-raw';
//i18n
import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';

import { agreementText } from '../../actions/user';
import { useUserStateDispatch } from '../../context/UserContext';
import MuiUIPicker from '../MUIDatePicker';
import { isValidPhone, reqRegField } from '../../helpers/validators';
import dayjs from 'dayjs';
import config from '../../config';
import Loading from '../Loading';
import SignUpFormConfirm from './SignUpFormConfirm';

const useStyles = makeStyles((theme) => ({
  creatingButtonContainer: {
    marginBottom: theme.spacing(3),

    height: 46,
    display: 'flex',
  },
  submitButton: {
    height: 46,
    textTransform: 'none',
    width: '100%',
    minWidth: 240,
    marginTop: 12,
  },
  errorMessage: {
    //textAlign: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
  },

  loginLoader: {
    marginLeft: theme.spacing(4),
  },

  passwordSuccess: {
    color: theme.palette.success.dark,
  },
  passwordError: {
    color: theme.palette.error.main,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUpForm({
  values,
  handleChange,
  setValues,
  setErrors,
  validate,
  errors,
  handleSubmit,
  handleDateChange,
  handleChangeGender,
  handlePhoneChange,
  setActiveTabId,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    userState: { appInfo, serverResponse, isLoaded },
  } = useUserStateDispatch();

  const [visibilePass, setVisibilePass] = useState(false);
  const [visibileRePass, setVisibileRePass] = useState(false);

  const [openModal, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!openModal);
  };

  // get agreement
  const [agreement, setAgreement] = useState({
    text: '',
    header: '',
    loading: false,
    error: '',
  });

  // useEffect(() => {
  //   const vals = {
  //     login: 'oleglamibn+52@gmail.com',
  //     password: 'Rock11city!',
  //     repassword: 'Rock11city!',
  //     gender: 'M',
  //     lastName: 'Los',
  //     firstName: 'Olegus',
  //     middleName: 'Servantes',
  //   };
  //   setValues(vals);
  // }, []);

  const handleAgree = (e) => {
    const vals = {
      ...values,
      [e.target.name]: e.target.checked,
    };
    setValues(vals);
  };

  const loginLabel = () => {
    switch (appInfo.authIdentifiers) {
      case 'email': {
        return t('COMPONENT.FORM_EMAIL');
      }
      case 'phone': {
        return t('COMPONENT.FORM_PHONE');
      }
      default: {
        return t('COMPONENT.FORM_EMAIL_OR_PHONE');
      }
    }
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE';

  // console.log(
  //   'appInfo.requiredRegistrationFields',
  //   appInfo.requiredRegistrationFields,
  //   'reqRegField',
  //   reqRegField(appInfo.requiredRegistrationFields, 'lastName'),
  // );

  const anicuraTerms =
    config.theme === 'white_fang' &&
    (!values.agreement1 || !values.agreement2);
  const disabledSubmitButton =
    !values.login ||
    !values.agreement ||
    !isEmpty(errors) ||
    anicuraTerms;

  return (
    <>
      <Typography variant="h3" mt={2} mb={2}>
        {t('SIGN.UP_BUTTON')}
      </Typography>

      <Fade
        styte={{ width: '100%' }}
        in={serverResponse?.action != null}
        style={
          serverResponse?.action != null
            ? { display: 'flex' }
            : { display: 'none' }
        }
      >
        <Alert
          severity={
            serverResponse?.action === 'OK' ? 'success' : 'warning'
          }
          className={classes.errorMessage}
        >
          <Typography variant="body2">
            {serverResponse?.action === 'OK'
              ? t('SIGN.UP_OK')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Email'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_EMAIL')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'Sms'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_SMS')
              : serverResponse?.action === 'CONFIRM_CODE_SENT' &&
                serverResponse?.deliveryMethod === 'VoiceCall'
              ? t('SIGN.CH_CONFIRM_CODE_SENT_VOICE')
              : serverResponse?.action === 'WRONG_LOGIN_OR_PASSWORD'
              ? t('SIGN.UP_WRONG_LOG_OR_PASS')
              : serverResponse?.action === 'EMAIL_ALREADY_REGISTERED'
              ? t('SIGN.UP_EMAIL_ALREADY_REG')
              : serverResponse?.action === 'MAY_LOGIN'
              ? t('SIGN.UP_MAY_LOGIN')
              : serverResponse?.action === 'WRONG_CONFIRMATION_CODE'
              ? t('SIGN.UP_CONFIRM_CODE_WRONG')
              : serverResponse?.action}
          </Typography>
        </Alert>
      </Fade>
      {isConfirmForm ? (
        <SignUpFormConfirm
          values={values}
          handleChange={handleChange}
          errors={errors}
          handleSubmit={handleSubmit}
          setActiveTabId={setActiveTabId}
        />
      ) : (
        <Grid
          container
          spacing={2}
          mt={1}
          mb={3}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={12}>
            <Input
              name="login"
              margin="normal"
              variant="outlined"
              value={values.login || ''}
              onBlur={(e) =>
                appInfo.authIdentifiers !== 'phone' &&
                isValidPhone(e.target.value, appInfo.countryCode) &&
                handlePhoneChange(e)
              }
              onChange={(e) =>
                appInfo.authIdentifiers === 'phone'
                  ? handlePhoneChange(e)
                  : handleChange(e)
              }
              label={loginLabel()}
              type="text"
              fullWidth
              required
              error={values.login != null && errors?.login != null}
              helperText={
                values.login != null &&
                errors?.login != null &&
                errors?.login
              }
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel htmlFor="outlined-adornment-password">
                {t('COMPONENT.FORM_PASS')}
              </InputLabel>
              <OutlinedInput
                name="password"
                autoComplete="new-password"
                variant="outlined"
                value={values.password || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_PASS')}
                type={visibilePass ? 'text' : 'password'}
                required
                error={errors.password != null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setVisibilePass(!visibilePass)}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!visibilePass ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText className={classes.passwordError}>
                {errors.password != null && errors.password}
              </FormHelperText>
            </FormControl>
            {!isEmpty(errors.passRules) &&
              Object.keys(errors.passRules).map((key) => {
                const clsName = errors.passRules[key].valid
                  ? classes.passwordSuccess
                  : classes.passwordError;
                return (
                  <Grid
                    key={key}
                    container
                    direction="row"
                    justifyContent="flex-start"
                  >
                    <Grid item xs={1} style={{ paddingLeft: 8 }}>
                      {errors.passRules[key].valid ? (
                        <CheckIcon className={clsName} />
                      ) : (
                        <CloseIcon className={clsName} />
                      )}
                    </Grid>
                    <Grid item xs={11} style={{ paddingLeft: 14 }}>
                      <FormHelperText className={clsName}>
                        {errors.passRules[key].ruleText}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel htmlFor="outlined-adornment-password">
                {t('COMPONENT.FORM_REPASS')}
              </InputLabel>
              <OutlinedInput
                name="repassword"
                autoComplete="new-password"
                variant="outlined"
                value={values.repassword || ''}
                onChange={handleChange}
                label={t('COMPONENT.FORM_REPASS')}
                type={visibileRePass ? 'text' : 'password'}
                required
                error={errors.repassword != null}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setVisibileRePass(!visibileRePass)
                      }
                      onMouseDown={handleMouseDownPassword}
                    >
                      {!visibileRePass ? (
                        <VisibilityOffIcon color="primary" />
                      ) : (
                        <VisibilityIcon color="primary" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <FormHelperText className={classes.passwordError}>
                {errors.repassword != null && errors.repassword}
              </FormHelperText>
            </FormControl>
          </Grid>
          {!appInfo.isSimpleRegistrationForm && (
            <>
              <Grid item xs={12} sm={6} mt={2}>
                <Input
                  name="lastName"
                  variant="outlined"
                  autoComplete={'off'}
                  value={values.lastName || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_LAST_NAME')}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  required={reqRegField(
                    appInfo.requiredRegistrationFields,
                    'lastName',
                  )}
                  error={errors.lastName != null}
                  helperText={
                    errors.lastName != null && errors.lastName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <Input
                  name="firstName"
                  variant="outlined"
                  autoComplete={'off'}
                  value={values.firstName || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_NAME')}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  required={reqRegField(
                    appInfo.requiredRegistrationFields,
                    'firstName',
                  )}
                  error={errors.firstName != null}
                  helperText={
                    errors.firstName != null && errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Input
                  name="middleName"
                  variant="outlined"
                  autoComplete={'off'}
                  value={values.middleName || ''}
                  onChange={handleChange}
                  label={t('COMPONENT.FORM_MIDLE_NAME')}
                  type="text"
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  error={errors.middleName != null}
                  helperText={
                    errors.middleName != null && errors.middleName
                  }
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MuiUIPicker
                  margin="none"
                  label={t('COMPONENT.BIRTH_DATE')}
                  value={
                    values.birthDate != null
                      ? dayjs(values.birthDate)
                      : null
                  }
                  handleChange={handleDateChange}
                  defaultValue={dayjs().add(
                    appInfo.ageLimitHigh,
                    'years',
                  )}
                  minDate={dayjs().add(appInfo.ageLimitLow, 'years')}
                  maxDate={dayjs().add(appInfo.ageLimitHigh, 'years')}
                  required={reqRegField(
                    appInfo.requiredRegistrationFields,
                    'birthDate',
                  )}
                  errorText={errors.birthDate}
                  name="date-picker-birthDate"
                />
              </Grid>
              <Grid item xs={12} sm={6} mt={2}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required={reqRegField(
                    appInfo.requiredRegistrationFields,
                    'gender',
                  )}
                  error={errors.gender != null && errors.gender}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    {t('COMPONENT.FORM_GENDER')}
                  </InputLabel>
                  <Select
                    //labelId="demo-simple-select-outlined-label"
                    //id="demo-simple-select-outlined"
                    name="gender"
                    value={values.gender != null ? values.gender : ''}
                    onChange={handleChangeGender}
                    label={t('COMPONENT.FORM_GENDER')}
                  >
                    <MenuItem value="M">
                      {t('COMPONENT.FORM_GENDER_M')}
                    </MenuItem>
                    <MenuItem value="F">
                      {t('COMPONENT.FORM_GENDER_F')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>
                    {errors.gender != null && errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1">
                      {t('SIGN.UP_AGEEMENT')}{' '}
                      <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                          agreementText({
                            agreement,
                            setAgreement,
                            isLocal: false,
                            header: t('SIGN.UP_TITLE_LIC_AGR'),
                          });

                          toggleModal();
                        }}
                        sx={{ pl: 0, pb: 1, fontSize: 16 }}
                      >
                        {t('SIGN.UP_AGEEMENT1')}
                      </Button>
                    </Typography>
                  }
                  sx={{ my: 1 }}
                />
              </Grid>
            </>
          )}

          {appInfo?.uiType === 'petClinic' && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1">
                      {t('SIGN.UP_AGEEMENT')}{' '}
                      <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                          agreementText({
                            agreement,
                            setAgreement,
                            isLocal: false,
                            header: t('SIGN.UP_TITLE_LIC_AGR'),
                          });
                          toggleModal();
                        }}
                        sx={{ pl: 0, pb: 1, fontSize: 16 }}
                      >
                        {t('SIGN.UP_AGEEMENT1')}
                      </Button>
                    </Typography>
                  }
                  sx={{ my: 1 }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement2"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label="Мне больше 18 лет"
                  sx={{ my: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement1"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body1">
                      Подтверждаю{' '}
                      <Button
                        color="primary"
                        variant="text"
                        onClick={() => {
                          agreementText({
                            agreement,
                            setAgreement,
                            isLocal: true,
                            header:
                              'Согласие на обработку персональных данных',
                          });

                          toggleModal();
                        }}
                        sx={{ pl: 0, pb: 1, fontSize: 16 }}
                      >
                        согласие на обработку персональных данных
                      </Button>
                    </Typography>
                  }
                  sx={{ my: 1 }}
                />
              </Grid>

              {/* <Grid item xs={12} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="agreement3"
                      onChange={handleAgree}
                      value={false}
                      color="primary"
                    />
                  }
                  label="Разрешаю получение информационных и рекламных сообщений от Белый клык"
                  sx={{ my: 1 }}
                />
              </Grid> */}
            </>
          )}

          <Grid item xs={12} textAlign="center" mb={3}>
            {!isLoaded ? (
              <Loading />
            ) : (
              <Button
                disabled={disabledSubmitButton}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                fullWidth
                className={classes.submitButton}
              >
                {t('SIGN.UP_BUTTON_SUBMIT')}
              </Button>
            )}
          </Grid>
        </Grid>
      )}
      <Modale
        open={openModal}
        toggleModal={toggleModal}
        title={agreement.header}
        my={3}
      >
        {!isLoaded ? (
          <Loading />
        ) : (
          <ReactMarkdown
            remarkPlugins={[remarkBreaks]}
            rehypePlugins={[rehypeRaw]}
          >
            {agreement.text}
          </ReactMarkdown>
        )}
      </Modale>
    </>
  );
}

export default SignUpForm;
