import React from 'react';
import { Avatar, Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { ReactComponent as CertificateIcon } from '../../images/icons/certificate.svg';
import { Link } from 'react-router-dom';
import { useUserStateDispatch } from '../../context/UserContext';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  link: {
    paddingLeft: 0,
  },
  banner: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1.2),
    border: `3px solid ${theme.palette.warning.main}`,
    padding: `${theme.spacing(1.5)} ${theme.spacing(
      0.5,
    )} ${theme.spacing(1.5)} ${theme.spacing(0.5)}`,
    marginBottom: theme.spacing(5),
  },
  itemIcon: {
    justifySelf: 'center',
    alignSelf: 'center',
    textAlign: 'center',
  },
  avatar: {
    backgroundColor: theme.palette.background.warning,
    color: theme.palette.info.main,
    margin: 'auto',
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  certificateIcon: {
    fill: theme.palette.warning.main,
    fontSize: 10,
  },
}));

export default function BannerCert() {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  return (
    appInfo.isPaymentsEnabled && (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.banner}
      >
        <Grid item xs={3} sm={1.5} className={classes.itemIcon}>
          <Avatar className={classes.avatar}>
            <CertificateIcon className={classes.certificateIcon} />
          </Avatar>
        </Grid>
        <Grid item xs sm>
          <Typography variant="h6">
            {t('INVOICES.BANNER_CERT_TEXT')}
          </Typography>
          <Button
            color="primary"
            variant="text"
            component={Link}
            to="/app/certs"
            className={classes.link}
          >
            {t('INVOICES.BANNER_CERT_BUTTON')}
          </Button>
        </Grid>
      </Grid>
    )
  );
}
