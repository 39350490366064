import React from 'react';
import {
  Grid,
  Typography,
  LinearProgress,
  CircularProgress,
} from '@mui/material';

interface LoadingProps {
  msg?: string;
  isLinear?: boolean;
  size?: number;
  variant?: 'h4' | 'h5' | 'h6' | 'body1' | 'body2';
}

const Loading: React.FC = ({
  msg,
  isLinear = false,
  size = 38,
  variant = 'h5',
}: LoadingProps) => (
  <Grid container spacing={3} sx={{ width: '100%', height: '100%' }}>
    {msg != null && (
      <Grid item>
        <Typography variant={variant}>{msg}</Typography>
      </Grid>
    )}
    <Grid
      item
      sx={{
        width: '100%',
        textAlign: msg == null ? 'center' : 'left',
      }}
    >
      {!isLinear ? (
        <CircularProgress size={size} />
      ) : (
        <LinearProgress />
      )}
    </Grid>
  </Grid>
);

export default Loading;
